const text = [
    { //fr

        usrsd : "Côté utilisateur",
        btsd : "Côté bot",
        nttkn : "Notes prises",
        mssdnt : "Notes manquées",
        gfts : "Cadeaux",
        rqsts : "Demandes",
        messonhldallctd : "Message en attente déjà attribué",
        messonhldntallctd : "Message en attente non attribué",
        totnummess : "Nombre total de messages",
        messpssdqc : "Messages ayant passé le contrôle qualité",
        messntpssqc : "Messages n'ayant pas passé le contrôle qualité",
        paymnts : "Paiements",
        bnk : "Banque",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Reçues",
        envoyees: "Envoyées",
        dmndees: "Demandées",
        nvaux: "Nouveaux",

        adddest: "Adresse e-mail du destinataire",

        cmpn : " Entreprise ",
        tmfld : " Textmoding folder ",
        accar : " Zone d'accès ",
        assvldt : " Validité d'accès ",

        acc8 : " Configuration des taux et des primes ",

        mtng : "Réunion",
        rp : "Real Profil",
        fp : "Fake Profil",
        infopernoper : "Informations permanentes / non permanentes",
        dltnt : "Supprimer la note",
        dltmesspnc : "Voulez-vous vraiment supprimer ce message de panique ?",
        convbnnd : "Conversation interdite",
        suspconv : "Suspendre la conversation",
        upldd : "Téléchargé",
        addpht : "Ajouter une photo",
        phtsnttofp : "Liste des photos envoyées aux faux profils",
        pkmesscrtd : "Message Poke créé avec succès",
        updt : "Mise à jour",
        dltpkmess : "Voulez-vous vraiment supprimer ce message poke ?",
        plt : "Plateforme",
        dltdmd : "Voulez-vous vraiment supprimer cette demande ? ",
        agntinfrmd : "Agent informed",
        dlttsrmess : "Voulez-vous vraiment supprimer ce message d'accroche ?",
        updttsrmess : "Mise à jour du message d'accroche",

        updtdsucc : "Mise à jour réussie",
        nonotif : "Pas de notification",
        continfo : "Content Information",
        exp : "Expéditeur",
        recep : "Récepteur",
        mdpupdtd : "Password updated successfully",
        opsusp : "Operator suspended successfully",
        opdec : "Operator disconnected successfully",
        decop : "Voulez-vous vraiment déconnecter cet opérateur :  ",
        frop : "Voulez-vous vraiment licencier cet opérateur :  ",
        chngmdp : "Changer le mot de passe",
        suspop : "Voulez-vous vraiment suspendre cet opérateur : ",
        onhld : "En attente",
        supprmess : "Supprimer ce message",
        messinfo : "Informations sur le message",
        vld : "Confirmer",
        ok : "Ok",
        enc : "En cours ...",

        updtmdp : "Voulez-vous changer le mot de passe ?",
        nwmdp : "Entrez le nouveau mot de passe",
        dltsup : "Voulez-vous vraiment supprimer ce superviseur ?",
        ntsup : "Note à un superviseur",
        nthr : "Note ici...",
        dltimg : "Voulez-vous vraiment supprimer cette image ?",
        phttsnd : "Photo à envoyer",
        vd : "Vide (sélectionner une photo)",
        addd : "Ajouté",
        nwrdv : "Ajouter une nouvelle information",
        cnfrm : "Confirmer",
        dltrdv : "Voulez-vous vraiment supprimer ce Rendez-vous/ Réunion/ Vacances?",
        addnonperminfo : "Ajouter des informations non permanentes",
        dltinfo : "Voulez-vous vraiment supprimer cette information ?",
        idd : "ID",
        addperminfo : "Ajouter des informations permanentes",
        messreport : "Message rapporté au superviseur",
        usrnm : "Nom",

        pp11 : "Creativ'Network, une société de mise en relation professionnelle pour des travaux du web, dont le siège social est situé au 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, et ayant son bureau de représentation au Roodborstjesstraat 4, Wondelgem 9032, ci-après dénommée « la Société »,",
        pp12 : "Et :",
        pp13 : "Travailleur indépendant, qui, en vertu du contrat de Travailleur Indépendant qui le lie avec « La Société » utilise les matériels ainsi que les propriétés intellectuelles de cette dernière, ci-après dénommé « le Travailleur Indépendant »,",
        pp14 : "Ci-après dénommées individuellement « la Partie » et collectivement « les Parties »,",
        pp21 : "La Société exploite une plateforme d'animation de sites de rencontres dont elle est la propriétaire exclusive. La gestion opérationnelle de cette plateforme est confiée à des opérateurs tchat, des superviseurs, des team leaders, des contrôleurs de qualité, employés en tant que travailleurs indépendants par la Société.",
        pp22 : "Dans le cadre de cette relation professionnelle, le Travailleur Indépendant sera amené à avoir accès à des informations confidentielles relatives à la plateforme, aux utilisateurs des sites de rencontres, aux processus opérationnels, ainsi qu'à toute autre information protégée appartenant à la Société.",
        pp23 : "Ceci étant exposé, il a été convenu ce qui suit :",
        pp31 : "Le présent accord de confidentialité a pour objet de protéger les informations confidentielles que la Société pourrait être amenée à communiquer au Travailleur Indépendant dans le cadre de ses fonctions liées à la gestion de la plateforme d'animation de sites de rencontres.",
        pp41 : "Aux fins du présent accord, les « Informations Confidentielles » désignent toutes les informations, sous quelque forme que ce soit (écrite, orale, électronique, etc.), relatives à la plateforme d'animation de sites de rencontres, aux utilisateurs, aux stratégies de développement, aux processus techniques et opérationnels, aux données financières, ainsi que toute autre information désignée comme confidentielle par la Société, que le Travailleur Indépendant pourrait recevoir ou avoir accès dans le cadre de son travail.",
        pp51 : "Le Travailleur Indépendant s'engage à :",
        pp52 : "- Ne pas divulguer, reproduire, transmettre, ou rendre accessible les Informations Confidentielles, en tout ou en partie, à des tiers, sans l'autorisation écrite préalable de la Société.",
        pp53 : "- Utiliser les Informations Confidentielles uniquement dans le cadre des activités liées à la gestion de la plateforme, et uniquement dans l'intérêt de la Société.",
        pp54 : "- Prendre toutes les mesures nécessaires pour protéger la confidentialité des Informations Confidentielles et empêcher toute utilisation non autorisée.",
        pp55 : "- Restituer à la Société, sur demande, tout document ou support contenant des Informations Confidentielles, sans en conserver de copies, sous quelque forme que ce soit.",
        pp61 : "Le présent accord prend effet au moment où le travailleur marquera son accord lors de son identification à l'accès à la plateforme d'animation et restera en vigueur pendant toute la durée de la relation professionnelle entre la Société et le Travailleur Indépendant. Les obligations de confidentialité subsisteront pendant une période de 10 ans après la fin de ladite relation, quelle qu'en soit la cause.",
        pp71 : "Ne sont pas considérées comme Informations Confidentielles :",
        pp72 : "- Les informations qui étaient déjà connues du public au moment de leur communication au Travailleur Indépendant, ou qui deviendraient publiques autrement que par la violation du présent accord.",
        pp73 : "- Les informations que le Travailleur Indépendant peut prouver avoir reçues de manière légitime d'un tiers non lié par une obligation de confidentialité.",
        pp74 : "- Les informations développées de manière indépendante par le Travailleur Indépendant sans l'utilisation des Informations Confidentielles.",
        pp81 : "En cas de manquement aux obligations prévues par le présent accord, le Travailleur Indépendant reconnaît que la Société pourra engager toutes les actions légales nécessaires pour protéger ses droits et obtenir réparation des dommages subis.",
        pp91 : "Le présent accord est régi par le droit belge. En cas de litige relatif à l'interprétation ou à l'exécution du présent accord, les Parties s'engagent à rechercher une solution amiable. À défaut d'accord amiable, les tribunaux compétents de Gand seront seuls compétents.",

        pp1 : "Entre les Parties",
        pp2 : "Préambule",
        pp3 : "Article 1 : Objet",
        pp4 : "Article 2 : Définition des Informations Confidentielles",
        pp5 : "Article 3 : Obligations du Travailleur Indépendant",
        pp6 : "Article 4 : Durée",
        pp7 : "Article 5 : Exclusions",
        pp8 : "Article 6 : Dispositions Générales",
        pp9 : "Article 7 : Loi Applicable et Juridiction Compétente",
        paymail: 'Adresse e-mail de paiement',
        scc : 'Créé',
        login: 'Connexion',
        password: 'Mot de passe',

        mail:'Email',
        qlogout: 'Voulez-vous vous déconnecter?',
        logout: 'Se déconnecter',
        yes: 'Oui',
        annuler:'Annuler',
        no: 'Non',
        editer:'Editer',
        editNote: 'Edition du note',
        notesuppr:'Note supprimée',
        operatorpage: "Page d'opérateur",
        supervisorpage: "Page de superviseur",
        adminpage: "Page d'administrateur",
        addnote:'Note ajoutée',
        required: "Ce champ est obligatoire",
        invalid: "Email invalide",
        registered: "Cette adresse e-mail n'est pas enregistrée",
        incorrectpass: "Mot de passe incorrect",
        atleast: "Le mot de passe doit contenir au moins",
        characters: "caractères",

        convman : "Gestion des conversations",
        agman : "Gestion des agents",
        notesman : "Gestion des notes",
        agrep : "Signalements des agents",
        gftgtfman : "Gestion des cadeaux",
        tmfldaa : "Autorisation d'accès au dossier TextModing",
        hlp : "Aide",
        pltflng : "Langue de la plateforme",
        pr: "Demandes de photos",
        wlcm : "Bienvenue",
        wlcmtxt : "TextModing est une plateforme d'animation conçue pour les sites de rencontres, offrant des fonctionnalités essentielles pour la gestion des conversations et des agents. Elle améliore l'efficacité et la rentabilité des partenaires en fournissant des outils qui rationalisent les interactions et optimisent l'engagement des utilisateurs.",

        messageto: 'Message à',
        convatt: 'Conversation en attente',
        mincharlen: 'Au moins 120 caractères',
        char: 'caractère',
        chars:  'caractères',
        pj: 'Photo jointe',
        writemessage: 'Ecrire un message ...',
        picalreadysent: "Photo vue par l'utilisateur",
        gifpj: 'GIFS',
        opengif: 'Ouvrir GIF',
        sendMessage: 'Envoyer le message',
        reportSup: 'Envoyer une note au superviseur',
        conv: 'Conversation ID',
        say: 'dit',
        timeIntro: 'à',
        elements: 'éléments',
        element: 'élément',
        none: 'Aucun',

        stat: 'Statistiques',
        french: 'Français',
        english: 'Anglais',
        italian: 'Italien',
        dutch: 'Néérlandais',
        spanish: 'Espagnol',
        german: 'Allemand',
        botprofil: 'Profil du Bot',
        userprofil: "Profil de l' utilisateur",
        otherInfo: 'Autres informations importantes',
        codepost: 'Code postal',
        ville: 'Ville',
        region: 'Région',
        pays: 'Pays',
        taille: 'Taille',
        eyes: 'Couleur des yeux',
        hair: 'Couleur des cheveux',
        morph: 'Morphologie',
        situation: 'État civil',
        search: 'Recherche',
        job: 'Profession',
        signe: 'Signes distinctifs',
        descri: 'Description',
        infosupp: 'Infos supplémentaires',

        supervisiontchat: 'Supervision tchat',
        statconvop: 'Stat et conversation opérateur',
        convs: 'Conversations',
        report: 'Report',
        agentInform:'Agent informé',
        events: 'Evènement',
        photosrequests: 'Demande de photo',
        warmup: 'Warmup',
        gifattch: 'Attachement gif',
        fakeprofiles: 'Faux profils',
        pagenotfound: 'Page introuvable',

        suivvraiprof: 'Suivi des vrais profils',
        suiviop: 'Suivi des opérateurs',
        admintion: 'Administration',
        affiliation : 'Affiliation',

        operator: 'Opérateur',
        supervisor: 'Superviseur',
        administrator: 'Administrateur',

        week: 'semaine',
        weeks: 'semaines',
        today: "Aujourd'hui",

        start: 'Début',
        end: 'Fin',
        platform: 'Plateformes',
        searchfor: 'Rechercher',
        go: 'Aller',
        allmasc: 'Tous',
        allfem: 'Toutes',

        id: 'Identifiant',
        opname: 'Nom opérateur',
        sentmess: 'Messages envoyés',
        recmess: 'Messages reçus',
        relmess: 'Messages de relance',

        date: 'Date',
        messid: 'Identifiant message',
        convid: 'Identifiant conversation',
        exp: "Sender",
        recpt: 'Récepteur',
        opsign: 'Opérateur à signaler',
        opexped: 'Opérateur expéditeur',
        action: 'Action',
        messs: 'Messages',
        filterdate: 'Filtrer les recherches entre deux dates',
        filtermess: 'Filtrer conversation par message',
        from: 'De',
        to: 'à',
        eventList: 'Liste des évènements',
        num: 'Numéro',
        eventSearch: 'Recherche un évènement',
        actions: 'Actions',

        eventname: "Nom de l'évènement",
        eventdate: "Date de l'évènement",
        uploadimage: "Télécharger des images",
        role: "Rôle",
        logIn: "Se connecter",
        ppaccept1: "En vous connectant à notre plateforme, vous confirmez que vous avez lu notre",
        ppaccept2: "et que vous avez suivi la formation appropriée pour mener à bien votre mission",
        pp: "accord de confidentialité",
        copyright: "© 2023-2024 Copyright TextModing® - Tous droits réservés",
        accountant: 'Comptable',
        qualitycontroller: 'Contrôleur de qualité',
        adminid: "ID administrateur",
        accountantid: "ID comptable",
        operatorid: "ID opérateur",
        qcid: "ID contrôleur de qualité",
        confirmpass:'Confirmez le mot de passe',
        alreadyreg:'Déjà enregistré ? Se connecter',
        notifs : "Notifications",
        limite:'Limité',
        save:'Sauvegarder',
        archives:'Archives',
        ttlmsg:'Total des messages',
        controlLog:'Login Contrôleur',
        nbrmess:'Nombre de messages',
        appQC:'Appréciations du QC',
        esqmq:'Esquive manquant',
        dbspace:'Double espace',
        notelu:'Note lue',
        actpris:'Action prise',
        ferme:'Fermé',
        losn:'Liste des notes envoyées',
        lorn : 'Liste des notes reçues',
        validity:'Validité',
        editInfo:'Modifier une information',
        controlID:'Contrôleur ID',
        statcontrolct:'Statistiques de contrôle par contrôleur',
        statcontrolag:'Statistiques de contrôle par Agent',
        statcontrol:'Statistiques de contrôle',
        traitement:'Traitement',
        agentid:'Agent ID',
        statconvatt: 'Statistiques des Conversations en Attente',
        propesqu : 'Saisissez une esquive à une proposition de rendez-vous',
        msgacc:"Veuillez rédiger un message d'accroche",
        msgpoke:'Veuillez rédiger un message Poke',
        heure:'Heure',
        esqv:'Esquive',
        esquive:'Esquives rencontres et rendez-vous',
        other:'Autres',
        sexe:'Sexe',
        bannir:'Bannir',
        deconnecter: 'Déconnecter',
        agntLgn:'Agent en ligne',
        features:'Fonctionnalités',
        rdv:'Rendez-vous',
        holiday:'Vacances',
        valider:'Valider',
        panicmsg:'ce message de panique ?',
        jour:'Jour',
        jours:'Jours',
        conversation:'Conversation',
        profil:'Profil',
        suspendre:'Suspendre',
        expulser:'Expulser',
        information:'Information',
        meeting:'Réunion',
        online:'En ligne',
        onHold:'En attente',
        treated:'Traitées',
        listPhotoSend : 'Liste de photos envoyées',
        suspendu:'Suspendu',
        suspend:'Suspendre',
        etat: 'Etat',
        
        actqc4: 'Message validé',
        panicmess:'Message de panique',
        contenu:'Contenu',
        raison:'Raison',
        reportmess:'Signaler un message',
        language1:'Langage',
        reg:"S'inscrire",
        enreg:["Pas encore inscrit ?", "S'enregistrer"],
        passdiff:'Mot de passe différent',
        supervisorid: "ID Superviseur",
        convonhold : "Conversations en attente",
        supdash : "Tableau de bord du superviseur",
        choverv : "Aperçu du chat",
        months: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        year: 'Année',
        month: 'Mois',
        startingdate : "Date de début",
        closingdate : "Date de clôture",
        agentReport: 'Agent signalé',
        agents: "Agents",
        out: "Sortis",
        in: "Entrés",
        details: "Détails",
        panicroom: "Salle de panique",
        total: "Total",
        notes: "Notes",
        teasermessages: "Messages d'accroche",
        pokemessages: "Messages Poke",
        attdisc:'Attente de Discussion',
        gifajt:'Gif Ajouter',
        stopmessages: "Messages d'arrêt",
        sent: "Envoyés",
        asnwered: "Répondus",
        requested: "Demandés",
        logbook: "Journal de bord",
        recorded: "Enregistrés",
        notrecorded: "Non enregistrés",
        duplicatedmessages: "Messages dupliqués",
        back: "Retour",
        suty : "Type d'abonnement",
        ag : "Âge",
        gend : "Sexe",
        sexor : "Orientation sexuelle",
        tow : "Ville",
        
        users: "Utilisateurs",
        sender: "Expéditeur",
        receiver: "Récepteur",
        agent: "Agent",
        status: "Statut",
        read: "Lu",
        active: "Actif",
        edit: "Modifier",
        user: "Utilisateur",
        sendnote : "Envoyer une note",
        oh : "En attente",
        mess : "Message",
        trconv : "Conversations traitées",
        idconv : "ID de la conversation",
        idmess : "ID du message",
        otherimp : "Autres informations importantes",
        otherimp1 : "Réunion/ Rencontres/ Vacances",
        otherimp2 : "Informations permanentes",
        otherimp3 : "Informations utiles, non permanentes",
        
        aglist : "Liste d'agents",
        pfsperf : "Performance des plateformes",
        agperf : "Performance des agents",
        nwag : "Ajouter de nouveaux agents",
        agname : "Nom de l'agent",
        agcountry : "Pays de l'agent",
        aglogin : "Login de l'agent",
        agloginpw : "Mot de passe de l'agent",
        langpf : "Plate-forme linguistique",
        onl : "En ligne",
        offl : "Hors ligne",
        susp : "Suspendre",
        koff : "Renvoyer",
        grotot : "Total brut",
        agnumbewoon : "Nombre de faux profils",
        totsenmess : "Total des messages envoyés",
        totrecmess : "Total des messages reçus",
        totrep : "Total des rapports",
        real : "Vrai nom",
        prov : "Province",
        weight : "Poids",
        child: "Enfants",
        time: "Temps",
        fstnm : "Prénom",
        lstnm : "Nom de famille",
        dtofbir : "Date de naissance",
        skypeid : "ID Skype",
        crnwag : "Créer un nouvel agent",
        usrprfl : "Profil de l'utilisateur",
        sndmess : "Envoyer un message",
        blckusr : "Bloquer l'utilisateur",
        lstnts : "Liste de notes",
        crtsndnts : "Créer / Envoyer une note",
        evnt : "Événement",
        ntid : "ID de la note",
        nt : "Note",
        dlt : "Supprimer",
        cndid: "ID du candidat",
        language: "Langue",
        perc1: "Vous avez composé ",
        perc2: " du message de l'utilisateur",
        giftsatt : "Cadeaux joints",
        sndnt : "Envoyer la note",
        lsttsrmsg : "Liste des messages d'accroche",
        crttsrmsg : "Créer des messages d'accroche",
        idtsrmsg : "ID du message d'accroche",
        tsrmsg : "Message d'accroche",
        dlvrdt : "Livré à",
        wiseg:"Gifs sages",
        sexg:"Gifs sexuels",
        gayg:"Gifs gays",
        Lesbg: "Gifs lesbiens",
         transg:"Gifs transgenres",
        travg:"Gifs travestis",
                
        bdsm:"BDSM",
        dont: "Ne soyez pas long",
        blj:"Fellation",
        org:"Orgasme",
        vagpen:"Pénétration vaginale",
        sod:"Sodomie",
        titss:"Nichons",

        bdsmg: "BDSM Gay",
        wisegg: "Gifs gays sages",
        sexgg:"Gifs gays sur le sexe",

        bdsml: "BDSM Lesbienne",
        wiselg:"Gifs Lesbiennes sages",
        sexlg:"Gifs de sexe lesbien",

        maletrans:"Transgenre masculin",
        femaletrans:"Femme transgenre",
        lstpkmsg : "Liste des messages poke",
        crtpkmsg : "Créer un message poke",
        idpkmsg : "ID du message poke",
        pkmsg : "Message poke",
        lststpmsg : "Liste des messages d'arrêt",
        idstpmsg : "ID du message d'arrêt",
        stpmsg : "Message d'arrêt",
        sntto : "Envoyé à",
        agntwrtngstp : "Agent rédigeant l'arrêt",
        dtofdlvr : "Date de livraison",
        dtofans : "Date de réponse",
        stpperfanal : "Arrêter l'analyse des performances",
        stpgrssttl : "Arrêter le total brut",
        rsptostp : "Réponse à la relance",
        rsptostp2 : "Réponse à l'accroche",
        rsptostp3 : "Réponse au Poke",
        stpprcntresp : "Arrêt % réponse",
        avrgrsptm : "Temps de réponse moyen",

        lstphtrq : "Liste des demandes de photos",
        phttp : "Type de photo",
        idrq : "ID de la demande",
        pht : "Photo",
        upld : "Upload",
        snd : "Envoyer",
        lstrprt : "Liste des rapports",
        rpttp : "Type de rapport",
        idrprt : "ID du rapport",
        msgrprtd : "Message rapporté",
        infrmagnt : "Informer l'agent",
        lstlgbk : "Liste du journal de bord",
        lstmssnglgbk : "Liste des carnets de route manquants",
        lgbktp : "Type de carnet de bord",
        idlgbk : "ID du journal de bord",
        infs : "Informations",
        lvlofimprt : "Niveau d'importance",
        prmnnt : "Permanente",
        ntprmnnt : "Non permanente",
        mssnglgbk : "Carnet de bord manquant",
        mrklgbk : "Marquer le carnet de bord",
        
        dh: "Date et heure",
        mid : "Message ID",
        repmess : "Signaler ce message",
        bts: "Bot dit",
        obj : "Objet",
        plc : "Lieu",

        lstdplmss : "Liste des messages dupliqués",
        iddplmss : "ID des messages dupliqués",
        lstgfs : "Liste des GIFs",
        ddgfs : "Ajouter un nouveau GIF",
        gftp : "Type de GIF",
        gfimg : "Image GIF",

        nat: "Nature",
        tmslt : "Tranche horaire",
        gfsnt : "GIFs envoyés",

        qcdash : "Tableau de bord du contrôleur de la qualité",
        qctrl : "Contrôle de la qualité",
        lstmess : "Liste des messages",
        qcpnl : "Canal de contrôle de la qualité",
        ptreq : "Demande de photo",
        sp : "Envoyer une photo",
        sgft : "Envoyer un cadeau",
        sgif : "Envoyer un Gif",
        qccrit : "Critères de qualité du QC",
        qccrit1 : "Bonne discussion",
        qccrit2 : "Mauvaise discussion",
        qccrit3 : "Profil d'utilisateur considéré",
        qccrit4 : "Profil du bot considéré",
        qccrit5 : "Conversation engagée par le bot",
        qccrit6 : "CTA par l'agent",
        qccrit7 : "Conversation insensée",
        qccrit8 : "Utilisateur mécontent du bot",
        qccrit9 : 'Note bien prise',
        actqc1 : "Informer l'agent",
        actqc2 : "Suspendre l'agent",
        actqc3 : "Renvoyer l'agent",
      
        sndmailtoadm : "Envoyer un courriel à l'administrateur",
        yrnm : "Votre nom",
        yrcmpnm : "Le nom de votre entreprise",
        yrmladdr : "Votre adresse électronique",
        yradmid : "Votre identifiant d'administrateur",
        objctfyrrqst : "Objet de votre demande",
        yrmss : "Votre message",
        yrattchmt : "Pièce jointe",
        sndthml : "Envoyer le courriel",
        ddnwadm : "Ajouter un nouvel administrateur",
        nm : "Nom",
        mladd : "Adresse électronique",
        cmpnnm : "Nom de la société",
        tmfldr : "Dossier TextModing",
        psswrd : "Mot de passe",
        accssar : "Zones d'accès",
        valdt : "Validité",
        rgstr : "Enregistrer",

        accdash : "Tableau de bord du comptable",
        adc1:'Excellent agent',
        adc2:'Agent moyen',
        adc3:'Agent médiocre',
        acc : "Comptes",
        acc1 : "Statistiques de l'agent",
        acc2 : "Paiement par virement",
        acc3 : "Paiement par Paypal",
        acc4 : "Paiement par wise",
        acc5 : "Paiement par Yoursafe",
        acc6 : "Ajouter un comptable",

        admdash : "Tableau de bord de l'administrateur",
        chttlov : "Vue d'ensemble de l'outil de chat",
        suptraffman : "Supervision et gestion du trafic",
        vw : "Voir",
        ratepermess : "Taux par message",
        bonus : "Bonus",
        totpay : "Paiement total",
        paymeth : "Mode de paiement",
        grosstot : "Total brut au mois de ",
        prstat : "Imprimer le relevé",
        agfn : "Prénom de l'agent",
        agln : "Nom de famille de l'agent",
        payadd : "Adresse e-mail Paypal",
        nysent : "Pas encore envoyé",
        wiseadd : "Adresse électronique de Wise",
        yoursafeadd : "Adresse électronique de Yoursafe",
        bankn : "Nom de la banque",
        iban : "IBAN",
        bic : "Code BIC",

        lstsup : "Liste des superviseurs",
        ddnwsup : "Ajouter un nouveau superviseur",
        trffcmng : "Gestion du trafic",
        supnm : "Nom du superviseur",
        suplgn : "Connexion du superviseur",
        suplgnpsswrd : "Mot de passe de connexion du superviseur",
        idcnddt : "ID du candidat",
        cntry : "Pays",
        lgn : "Connexion",
        crtnwsup : "Créer un nouveau superviseur",
        fragntonln : "Agent libre en ligne",
        msgalltag : "Message alloué à l'agent",
        allct : "Attribuer",
        addqc : "Ajouter un contrôleur de qualité",
        crtqc : "Créer un contrôleur de qualité",
        crtnwacc: "Créer un nouveau comptable",

        gifn: "GIFs à envoyer",
        restfn: "Réinitialiser",
        gifsel: "Vide (sélectionner des gifs)",
        alerttxt: "Gifs envoyés avec succès.",
        option: "options",

        phalert: "Demande de photo envoyée.",
        phtitre: "Demande de photo",
        phlabel: "Type de photo",
        phsend: "Envoyer",
        phload: "Chargement...",

        delalert: "Supprimé(e)",
        deltitre1: "Voulez-vous vraiment supprimer ",
        deltitre2: "cette demande ?",
        delauteur: "Auteur",
        deldesc: "Description",
        delaccept: "Oui",
        delrefuse: "Non",
        delload: "Charge..",

        lstqc :  "Liste des contrôleurs de qualité" ,
        qcnm :  "Nom du contrôleur qualité" ,
        qclgn :  "Login du contrôleur qualité" ,
        qclgnpsswrd :  "Mot de passe de connexion du contrôleur qualité" ,
        lstacc :  "Liste des comptables" ,
        accnm :  "Nom du comptable" ,
        acclgn :  "Login du Comptable" ,
        acclgnpsswrd :  "Mot de passe de connexion du comptable" ,

        biev: "Bienvenue",
        selecrt: "Interface d'envoi de message",
        chxha: "Choisissez un vrai et un faux profil",
        chxF: "Liste des vrais profils",
        chxV: "Liste des faux profils",
        nomd: "Nom",
        langs: "Langue",
        errdj: "Sélectionner un vrai et un faux profil",
        plch: "Entrer votre message",
        pokePh: "Poke Messages (pas encore envoyer)",
        teaserPh: "Liste des messages d'accroche (pas encore envoyer)",
        inter: "Interface de test de TextModing",
        interSel: "Veuillez sélectionner les tâches que vous voulez effectuer",
        des1: "Envoi de message personnalisé à un profil.",
        des2: "Envoi de message pour attirer l'attention du profil.",
        des3: "Envoi personnalisé de messages captivants à des nouveaux inscrits pour engager des conversations.",
        des4: "Envoi de message pour relancer la conversation.",
        tit1: "Envoi message",
        tit2: "Message d'accroche",
        tit3: "Message poke",
        tit4: "Message de relance",
        tache: "Tâche",
        bienvTest: "Bienvenue dans l'interface de test de",
        enterEP: "Entrer votre email et mot de passe",
        instTest: "Instructions",
        sendInt: "Interface d'envoi",
        unPlP: "Choisissez un ou plusieurs vrais profils",
        unNouv: "Nouvelle",
        unReç: "Reçus",
        unNouv1: "Nouveau",
        payTot: "Total de paiment",
        allou: "Déja alloués",
        Nallou: "Pas encore alloués",
        totSoum: "Total de message",
        Soum: "Soumis à une QC",
        nonSoum: "Non soumis à une QC",
        boot: "Bot",
        rvr: "Rendez-vous/Réunions/Vacances",
        banit: "Banni",
        suspt: "Suspendu",
        aucunt: "Aucun message",
        msgstop: "Veuillez rédiger un message de relance",
        messrt: "Taux de message",
        pkmessrt: "Taux de poke message",
        tsrmessrt: "Taux de message d'accroche",
        stpmessrt: "Taux de message de relance",
        bnsrt: "Taux de bonus",
        mesdes: "L'envoi de message sur un site de rencontres est crucial pour capter l'attention et susciter l'intérêt de l'autre personne. L'essentiel est de rester authentique, de montrer un intérêt sincère et de poser des questions ouvertes qui encouragent une réponse.",
        mesdes1: "Parmi les profils, choisissez un vrai et un faux pour envoyer un message.",
        mesdes2: "Veillez à bien choisir la langue de correspondance.",
        mesdes3: "Rédigez votre message test.",
        mesdes4: "Vous avez 5 minutes pour répondre au message dans la plateforme d'animation pour les opérateurs.",
        mesdes5: "Vérifiez vos stats dans votre tableau de bord d'administrateur.",
        stopdes: "Les messages de relance sur un site de rencontre sont destinés à raviver l'intérêt et à réengager la conversation après une période de silence.",
        stopdes1: "Choisissez un vrai et un faux profils",
        stopdes2: "Rédiger un message habituel",
        stopdes3: "Attendez 24 heures après que l'opérateur tchat ait répondu au message du vrai profil",
        stopdes4: "Après 24 heures, le message non répondu par le vrai profil se transforme en Message de relance, que l'opérateur se chargera de composer et de le lui envoyer.",
        stopdes5: "Vérifiez vos stats dans votre tableau de bord d'administrateur",
        teaserdes: "Les messages d'accroche sur un site de rencontres sont cruciaux pour capter l'attention et susciter l'intérêt de l'autre personne. L'essentiel est de rester authentique, de montrer un intérêt sincère et de poser des questions ouvertes qui encouragent une réponse",
        teaserdes1: "Parmi les profils, choisissez un vrai et un faux pour envoyer un message d'accroche",
        teaserdes2: "Veillez à bien choisir la langue de correspondance",
        teaserdes3: "Sur la plateforme d'animation pour les opérateurs, rédigez les messages d'accroche",
        teaserdes5: "Vérifiez vos stats dans votre tableau de bord d'administrateur",
        pokedes: "Les messages 'Poke' pour un site de rencontres sont des messages courts et légers, conçus pour attirer l'attention de manière subtile et engageante.",
        pokedes1: "Choisissez le pays et la langue des vrais profils avec lesquels vous voulez envoyer des messages Poke",
        pokedes2: "Choisissez un à plusieurs profils avec lesquels vous voulez envoyer des messages Poke",
        pokedes3: "Vérifiez vos stats dans votre tableau de bord d'administrateur",
        stopbla: "Liste de message de relance déjà envoyé",
        respVp: "Réponse du vrai profil",
        pasEn: "pas encore.",
        notifAccr: "Teste Message d'accroche: Choix Profils fait avec succès",
        notifPoke: "Teste Message Poke: Choix Profil fait avec succès",
        pokeErr: "cliquer un ou plusieurs vrai profil",
        teaserErr: "cliquez sur un faux et un vrai profil",
        reqGift : "Demande de cadeaux",
        reqGift1 : "Type de cadeau",
        reqGift2 : "Demande de cadeau",
    },


    { //en

        usrsd: "User side",
        btsd: "Bot side",
        nttkn: "Notes taken",
        mssdnt: "Missed notes",
        gfts: "Gifts",
        rqsts: "Requests",
        messonhldallctd: "Message on hold already allocated",
        messonhldntallctd: "Message on hold not allocated",
        totnummess: "Total number of messages",
        messpssdqc: "Messages passed QC",
        messntpssqc: "Messages not passing QC",
        paymnts: "Payments",
        bnk: "Bank",
        ppl: "Paypal",
        ws: "Wise",
        yrsf: "Yoursafe",
        rçes: "Received",
        envoyees: "Sent",
        dmndees: "Requested",
        nvaux: "New",

        adddest: "Recipient's e-mail address",

        cmpn: "Company",
        tmfld: "Textmoding folder",
        accar: "Access area",
        assvldt: "Access validity",

        acc8: "Rate and Bonus Set up",

        mtng: "Meeting",
        rp: "Real Profil",
        fp: "Fake Profil",
        infopernoper: "Permanent / non-permanent information",
        dltnt: "Delete note",
        dltmesspnc: "Do you really want to delete this panic message?",
        convbnnd: "Banned conversation",
        suspconv: "Suspend the conversation",
        upldd: "Uploaded",
        addpht: "Add photo",
        phtsnttofp: "List of photos sent to fake profiles",
        pkmesscrtd: "Poke message created successfully",
        updt: "Update",
        dltpkmess: "Do you really want to delete this poke message?",
        plt: "Platform",
        dltdmd: "Do you really want to delete this request? ",
        agntinfrmd: "Agent informed",
        dlttsrmess: "Do you really want to delete this teaser message?",
        updttsrmess: "Update teaser message",

        updtdsucc: "Updated successfully",
        nonotif: "No notification",
        continfo: "Content Information",
        exp: "Sender",
        recep: "Receiver",
        mdpupdtd: "Password updated successfully",
        opsusp: "Operator suspended successfully",
        opdec: "Operator disconnected successfully",
        decop: "Do you really want to disconnect this operator:  ",
        frop: "Do you really want to fire this operator:  ",
        chngmdp: "Change password",
        suspop: "Do you really want to suspend this operator: ",
        onhld: "On hold",
        supprmess: "Delete this message",
        messinfo: "Message information",
        vld: "Confirm",
        ok: "Ok",
        enc: "In progress ...",

        updtmdp: "Do you want to change the password?",
        nwmdp: "Enter new password",
        dltsup: "Do you really want to delete this supervisor?",
        ntsup: "Note to a supervisor",
        nthr: "Note here...",
        dltimg: "Do you really want to delete this picture?",
        phttsnd: "Photo to send",
        vd: "Empty (select a photo)",
        addd: "Added",
        nwrdv: "Add new information",
        cnfrm: "Confirm",
        dltrdv: "Do you really want to delete this Rendez-vous/ Meetings/ Holidays?",
        addnonperminfo: "Add non-permanent information ",
        dltinfo: "Do you really want to delete this information?",
        idd: "ID",
        addperminfo: "Add permanent information ",
        messreport: "Message reported to supervisor",
        usrnm: "Username",

    	paymail: 'Payment e-mail address',
        pp11: "Creativ'Network, a company specializing in professional networking for web-related work, with its registered office located at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, and having its representative office at Roodborstjesstraat 4, Wondelgem 9032, hereinafter referred to as 'the Company',",
        pp12: "And:",
        pp13: "An independent contractor who, under the Independent Contractor Agreement with 'the Company,' utilizes the materials and intellectual properties of the latter, hereinafter referred to as 'the Independent Contractor',",
        pp14: "Hereinafter individually referred to as 'the Party' and collectively as 'the Parties',",
        pp21: "The Company operates a dating site management platform of which it is the exclusive owner. The operational management of this platform is entrusted to chat operators, supervisors, team leaders, and quality controllers, employed as independent contractors by the Company.",
        pp22: "As part of this professional relationship, the Independent Contractor will have access to confidential information related to the platform, the users of the dating sites, the operational processes, and any other protected information owned by the Company.",
        pp23: "Having stated the above, it is agreed as follows:",
        pp31: "The purpose of this confidentiality agreement is to protect the confidential information that the Company may disclose to the Independent Contractor in connection with their duties related to the management of the dating site management platform.",
        pp41: "For the purposes of this agreement, 'Confidential Information' refers to all information, in any form (written, oral, electronic, etc.), related to the dating site management platform, the users, development strategies, technical and operational processes, financial data, as well as any other information designated as confidential by the Company that the Independent Contractor may receive or have access to in the course of their work.",
        pp51: "The Independent Contractor agrees to:",
        pp52: "- Not disclose, reproduce, transmit, or make accessible the Confidential Information, in whole or in part, to third parties without the prior written consent of the Company.",
        pp53: "- Use the Confidential Information solely in the context of activities related to the management of the platform and only in the interest of the Company.",
        pp54: "- Take all necessary measures to protect the confidentiality of the Confidential Information and prevent any unauthorized use.",
        pp55: "- Return to the Company, upon request, any document or medium containing Confidential Information, without retaining any copies in any form.",
        pp61: "This agreement becomes effective when the contractor consents during their identification for access to the management platform and will remain in force for the duration of the professional relationship between the Company and the Independent Contractor. The confidentiality obligations will survive for a period of 10 years following the end of this relationship, whatever the cause.",
        pp71: "The following are not considered Confidential Information:",
        pp72: "- Information that was already known to the public at the time it was disclosed to the Independent Contractor, or which becomes public other than through a breach of this agreement.",
        pp73: "- Information that the Independent Contractor can prove was received legitimately from a third party not bound by a confidentiality obligation.",
        pp74: "- Information developed independently by the Independent Contractor without using the Confidential Information.",
        pp81: "In the event of a breach of the obligations set forth in this agreement, the Independent Contractor acknowledges that the Company may take all necessary legal actions to protect its rights and seek compensation for damages suffered.",
        pp91: "This agreement is governed by Belgian law. In the event of a dispute regarding the interpretation or execution of this agreement, the Parties agree to seek an amicable resolution. Failing an amicable agreement, the competent courts of Ghent shall have exclusive jurisdiction.",
        pp1: "Between the Parties",
        pp2: "Preamble",
        pp3: "Article 1: Purpose",
        pp4: "Article 2: Definition of Confidential Information",
        pp5: "Article 3: Obligations of the Independent Contractor",
        pp6: "Article 4: Duration",
        pp7: "Article 5: Exclusions",
        pp8: "Article 6: General Provisions",
        pp9: "Article 7: Governing Law and Jurisdiction",
        scc: 'Created',
        login: 'Login',
        password: 'Password',
        confirmpass:'Confirm password',
        alreadyreg:'Already registered? Login',
        notifs: "Notifications",
        limite:'Limited',
        save:'Save',
        archives:'Archives',
        ttlmsg:'Total Messages',
        controlLog:'Controller Login',
        nbrmess:'Number of messages',
        appQC:'QC Appreciations',
        esqmq:'Missing dodge',
        dbspace:'Double space',
        notelu:'Note Read',
        actpris:'Action Taken',
        ferme:'Closed',
        losn:'List of sent Notes',
        lorn:'List of received Notes',
        validity:'Validity',
        editInfo:'Edit information',
        controlID:'ID Controller',
        statcontrolct:'Control Statistics by Controller',
        statcontrolag:'Control Statistics by Agent',
        statcontrol:'Control Statistics',
        traitement:'Treatment',
        agentid:'Agent ID',
        statconvatt:'Conversations on Hold Statistics',
        propesqu:'Enter an excuse for a date request',
        msgacc:'Please write a teaser message',
        msgpoke:'Please write a Poke message',
        heure:'Hour',
        esqv:'Dodge',
        esquive:'Dodges meetings and appointments',
        other:'Others',
        sexe:'Gender',
        bannir:'Ban',
        agntLgn : 'Online Agent',
        features:'Features',
        rdv:'Appointment',
        holiday:'Holiday',
        valider:'Validate',
        panicmsg:'this panic message?',
        jour:'Day',
        jours:'Days',
        conversation:'Conversation',
        profil:'Profile',
        suspendre:'Suspend',
        expulser:'Expel',
        information:'Information',
        meeting:'Meeting',
        online:'Online',
        total:'Total',
        onHold:'OnHold',
        treated:'Treated',
        listPhotoSend:'List of sent photos',
        agentReport:'Reported agent',
        suspendu:'Suspended',
        suspend:'Suspend',
        etat:'Status',
        deconnecter:'Disconnect',
        actqc4 : 'Message validated',
        panicmess:'Panic message',
        contenu:'Content',
        raison:'Reason',
        language1:'Language',
        reportmess:'Report a message',
        mail:'Email',
        reg:'Register',
        enreg:['Not registered yet?','Register'],
        passdiff:'Different password',
        addnote: 'Note added',
        qlogout: 'Do you want to log out?',
        annuler:'Cancel',
        notesuppr: 'Note deleted',
        editer:'Edit',
        editNote:'Edit Note',
        logout: 'Log Out',
        yes: 'Yes',
        no: 'No',
        operatorpage: "Operators page",
        supervisorpage: "Supervisors page",
        adminpage: "Administrators page",

        required: "This field is required",
        invalid: "Invalid email",
        registered: "This e-mail address is not registered",
        incorrectpass: "Incorrect password",
        atleast: "Password must contain at least",
        characters: "characters",

        convman: "Conversations management",
        agman: "Agents management",
        notesman: "Notes management",
        agrep: "Agents reports",
        gftgtfman: "Gifts and gifs management",
        tmfldaa: "TextModing folder access allowance",
        hlp: "Help",
        pltflng: "Platform language",
        pr: "Photo requests",
        wlcm: "Welcome",
        wlcmtxt: "TextModing is an animation platform designed for dating sites, offering essential features for managing conversations and agents. It enhances efficiency and profitability for partners by providing tools that streamline interactions and optimize user engagement.",

        messageto: 'Message to',
        convatt: 'Conversation on hold',
        mincharlen: 'At least 120 characters',
        char: 'character',
        chars:  'characters',
        pj: 'Photo attachment',
        writemessage: 'Write a message ...',
        picalreadysent: 'Photo seen by the user',
        gifpj: 'GIFS',
        opengif: 'Open GIF',
        sendMessage: 'Send the message',
        reportSup: 'Send a note to Supervisor',
        conv: 'Conversation ID',
        say: 'says',
        timeIntro: 'at',
        elements: 'elements',
        element: 'element',
        none: 'None',

        stat: 'Statistics',
        french: 'French',
        english: 'English',
        italian: 'Italian',
        dutch: 'Dutch',
        spanish: 'Spanish',
        german: 'German',
        botprofil: 'Bot Profile',
        userprofil: "User profile",
        otherInfo: 'Other important informations',
        codepost: 'Postal code',
        ville: 'City',
        region: 'Region',
        pays: 'Country',
        taille: 'Size',
        eyes: 'Eyes color',
        hair: 'Hair color',
        morph: 'Morphology',
        situation: 'Marital status',
        searchfor: 'Search for',
        job: 'Occupation',
        signe: 'Distinctive signs',
        descri: 'Description',
        infosupp: 'Additional information',

        supervisiontchat: 'Chat supervision',
        statconvop: 'Stat and operator conversation',
        convs: 'Conversations',
        report: 'Report',
        agentInform:'Informed agent',
        events: 'Event',
        photosrequests: 'Photo request',
        warmup: 'Warmup',
        gifattch: 'Gif attachment',
        fakeprofiles: 'Fake profiles',
        pagenotfound: 'Page not found',

        suivvraiprof: 'Real profiles monitoring',
        suiviop: 'Operators monitoring',
        admintion: 'Administration',
        affiliation : 'Membership',

        operator: 'Operator',
        supervisor: 'Supervisor',
        administrator: 'Administrator',

        week: 'week',
        weeks: 'weeks',
        today: 'Today',

        start: 'Start',
        end: 'End',
        platform: 'Platforms',
        search: 'Looking for',
        go: 'Go',
        allmasc: 'All',
        allfem: 'All',

        id: 'Identifier',
        opname: 'Operator name',
        sentmess: 'Sent messages',
        recmess: 'Messages received',
        relmess: 'Dunning messages',

        date: 'Date',
        messid: 'Message identifier',
        convid: 'Conversation identifier',
        exped: 'Sender',
        recpt: 'Receiver',
        opsign: 'Operator to report',
        opexped: 'Sending operatorr',
        action: 'Action',
        messs: 'Messages',
        filterdate: 'Filter searches between two dates',
        filtermess: 'Filter conversation by message',
        from: 'From',
        to: 'to',
        eventList: 'Events list',
        num: 'Number',
        eventSearch: 'Search for an event',
        actions: 'Actions',

        eventname: 'Event name',
        eventdate: 'Event date',
        uploadimage: "Upload images",
        role: "Role",
        logIn: "Log in",
        
        ppaccept1: "By logging on to our platform, you confirm that you have read our",
        ppaccept2: "and have taken the appropriate training to carry out your assignment.",
        pp: "confidentiality agreement",
        copyright: "© 2023-2024 Copyright TextModing® - All rights reserved.",
        accountant: 'Accountant',
        qualitycontroller: 'Quality controller',
        adminid: "Admin ID",
        accountantid: "Accountant ID",
        operatorid: "Operator ID",
        qcid: "Quality Controller ID",
        supervisorid: "Supervisor ID",
        convonhold: "Conversations on hold",
        supdash: "Supervisor dashboard",
        choverv: "Chat overview",
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        year: 'Year',
        month: 'Month',
        startingdate: 'Starting date',
        closingdate: 'Closing date',

        agents: "Agents",
        out: "Out",
        in: "In",
        details: "Details",
        panicroom: "Panic room",
        notes: "Notes",
        teasermessages: "Teaser messages",
        pokemessages: "Poke messages",
        attdisc:'Waiting for Discussion',
        gifajt:'Gif Add',
        stopmessages: "Stop messages",
        sent: "Sent",
        asnwered: "Answered",
        requested: "Requested",
        logbook: "Log book",
        recorded: "Recorded",
        notrecorded: "Not recorded",
        duplicatedmessages: "Duplicated messages",
        back: "Back",


        suty: "Subscription type",
        ag: "Age",
        gend: "Gender",
        sexor: "Sexual orientation",
        tow: "Town",
        
        users: "Users",
        sender: "Sender",
        receiver: "Receiver",
        agent: "Agent",
        status: "Status",
        read: "Read",
        active: "Active",
        edit: "Edit",
        user: "User",
        sendnote: "Send note",
        oh: "On hold",
        mess: "Message",
        trconv: "Treated conversations",
        idconv: "Conversation ID",
        idmess: "Message ID",
        otherimp: "Other important informations",
        otherimp1: "Meeting/ Meetings/ Holidays",
        otherimp2: "Permanent informations",
        otherimp3: "Useful informations, not permanent ",
        
        aglist: "List of agents",
        pfsperf: "Platforms performance",
        agperf: "Agents performance",
        nwag: "Add new agents",
        agname: "Agent name",
        agcountry: "Agent country",
        aglogin: "Agent login",
        agloginpw: "Agent login password",
        langpf: "Language platform",
        onl: "Online",
        offl: "Offline",
        susp: "Suspend",
        koff: "Kick off",
        grotot: "Gross Total",
        agnumbewoon: "Number of fake profiles",
        totsenmess: "Total messages sent",
        totrecmess: "Total messages received",
        totrep: "Total reports",
        real: "Real name",
        prov: "Province",
        weight: "Weight",
        child: "Children",
        time: "Time",
        fstnm: "First name",
        lstnm: "Last name",
        dtofbir: "Date of birth",
        skypeid: "Skype ID",
        crnwag: "Create new agent",
        usrprfl: "User profile",
        sndmess: "Send message",
        blckusr: "Block user",
        lstnts: "List of notes",
        crtsndnts: "Create / Send a note",
        evnt: "Event",
        ntid: "Note ID",
        nt: "Note",
        dlt: "Delete",
        cndid: "Candidate ID",
        language: "Language",
        perc1: "You have composed ",
        perc2: "of the user's message",
        giftsatt: "Gifts attachment",
        sndnt: "Send the note",
        lsttsrmsg: "List of teaser messages",
        crttsrmsg: "Create teaser messages",
        idtsrmsg: "Teaser message ID",
        tsrmsg: "Teaser message",
        dlvrdt: "Delivered to",
        wiseg: "Wise Gifs",
        sexg: "Sexual Gifs",
        gayg: "Gay Gifs",
        Lesbg: "Lesbian Gifs",
        transg: "Transgender Gifs",
        travg: "Travestite Gifs",
        
        bdsm: "BDSM",
        dont: "Don't be long",
        blj: "Blowjob",
        org: "Orgasm",
        vagpen: "Vaginal penetration",
        sod: "Sodomy",
        titss: "Tits",

        bdsmg: "BDSM Gay",
        wisegg: "Wise Gay Gifs",
        sexgg: "Sex Gay Gifs",

        bdsml: "BDSM Lesbian",
        wiselg: "Wise Lesbian Gifs",
        sexlg: "Sex Lesbian Gifs",

        maletrans: "Male transgender",
        femaletrans: "Female transgender",
        lstpkmsg: "List of poke messages",
        crtpkmsg: "Create poke message",
        idpkmsg: "Poke message ID",
        pkmsg: "Poke message",
        lststpmsg: "List of stop messages",
        idstpmsg: "Stop message ID",
        stpmsg: "Stop message",
        sntto: "Sent to",
        agntwrtngstp: "Agent writing the stop",
        dtofdlvr: "Date of delivery",
        dtofans: "Date of answer",
        stpperfanal: "Stop performance analysis",
        stpgrssttl: "Stop gross total",
        rsptostp: "Response to stop",
        rsptostp2: "Response to the Teaser",
        rsptostp3: "Response to the Poke",
        stpprcntresp: "Stop % response",
        avrgrsptm : "Average response time",

        lstphtrq: "List of photos request",
        phttp: "Photo type",
        idrq: "Request ID",
        pht: "Photo",
        upld: "Upload",
        snd: "Send",
        lstrprt: "List of reports",
        rpttp: "Report type",
        idrprt: "Report ID",
        msgrprtd: "Message reported",
        infrmagnt: "Inform agent",
        lstlgbk: "List of log book",
        lstmssnglgbk: "List of missing log book",
        lgbktp: "Log book type",
        idlgbk: "Log book ID",
        infs: "Informations",
        lvlofimprt: "Level of importance",
        prmnnt: "Permanent",
        ntprmnnt: "Not permanent",
        mssnglgbk: "Missing log book",
        mrklgbk: "Mark log book",
        
        dh: "Date and Hour",
        mid: "Message ID",
        repmess: "Report this message",
        bts: "Bot says",
        obj: "Object",
        plc: "Place",

        lstdplmss: "List of duplicated messages",
        iddplmss: "Duplicated messages ID",
        lstgfs: "List of GIFs",
        ddgfs: "Add new GIF",
        gftp: "GIF type",
        gfimg: "GIF image",

        nat: "Nature",
        tmslt: "Time Slot",
        gfsnt: "GIFs sent",

        qcdash: "Quality Controller Dashboard",
        qctrl: 'Quality control',
        lstmess: "List of messages",
        qcpnl: "Quality control pannel",
        ptreq: "Request photo",
        sp: "Send photo",
        sgft: "Send Gift",
        sgif: "Send Gif",
        qccrit: "QC criterias",
        qccrit1: "Good Chat",
        qccrit2: "Bad Chat",
        qccrit3: "User profile regarded",
        qccrit4: "Bot profile regarded",
        qccrit5: "Conversation engaged by the bot",
        qccrit6: "CTA by agent",
        qccrit7: "Conversation non sense",
        qccrit8: "User unhappy about the bot",
        qccrit9:'Note well taken',
        actqc1: "Inform the agent",
        actqc2: "Suspend the agent",
        actqc3: "Fire the agent",
        adc1:'Excellent agent',
        adc2:'Average agent',
        adc3:'Poor agent',
        sndmailtoadm: "Send an email to the admin",
        yrnm: "Your name",
        yrcmpnm: "Your company name",
        yrmladdr: "Your email address",
        yradmid: "Your admin ID",
        objctfyrrqst: "Object of your request",
        yrmss: "Your message",
        yrattchmt: "Attachment",
        sndthml: "Send the email",
        ddnwadm: "Add new admin",
        nm: "Name",
        mladd: "Email address",
        cmpnnm: "Company name",
        tmfldr: "TextModing folder",
        psswrd: "Password",
        accssar: "Access areas",
        valdt: "Validity",
        rgstr: "Register",

        accdash: "Accountant Dashboard",
        acc: "Accounts",
        acc1: "Agent statistics",
        acc2: "Payment by wire transfer",
        acc3: "Payment by Paypal",
        acc4: "Payment by wise",
        acc5: "Payment by Yoursafe",
        acc6: "Add an accountant",

        admdash: "Admin dashboard",
        chttlov: "Chattool overview",
        suptraffman: "Supervising and traffic Management",
        vw: "View",
        
        ratepermess: "Rate per message",
        bonus: "Bonus",
        totpay: "Total payment",
        paymeth: "Payment method",
        grosstot: "Gross Total as at month of ",
        prstat: "Print the statement",
        agfn: "Agent first name",
        agln: "Agent last name",
        payadd: "Paypal e-mail address",
        nysent: "Not yet sent",
        wiseadd: "Wise e-mail address",
        yoursafeadd: "Yoursafe e-mai,l address",
        bankn: "Bank name",
        iban: "IBAN",
        bic: "BIC code",

        lstsup: "List of supervisors",
        ddnwsup: "Add new supervisor",
        trffcmng: "Traffic management",
        supnm: "Supervisor name",
        suplgn: "Supervisor login",
        suplgnpsswrd: "Supervisor login password",
        idcnddt: "Candidate ID",
        cntry: "Country",
        lgn: "Login",
        crtnwsup: "Create new supervisor",
        fragntonln: "Free agent online",
        msgalltag: "Message allocation to agent",
        allct: "Allocate",
        addqc: "Add Quality Controller",
        crtqc: "Create Quality Controller",
        crtnwacc: "Create new accountant",

        gifn: "GIFs to send",
        restfn: "Reset",
        gifsel: "Empty (select gifs)",
        alerttxt: "GIFs sent successfully.",
        option: "options",

        phalert: "Request for sent photo.",
        phtitre: "Photo request",
        phlabel: "Type of photo",
        phsend: "Send",
        phload: "loading...",

        delalert: "Deleted",
        deltitre1: "Do you really want to delete ",
        deltitre2: "this request ?",
        delauteur: "Author",
        deldesc: "Description",
        delaccept: "Yes",
        delrefuse: "No",
        delload: "Load..",

        lstqc: "List of Quality Controller",
        qcnm: "Quality controller name",
        qclgn: "Quality controller login",
        qclgnpsswrd: "Quality controller login password",
        lstacc: "List of Accountant",
        accnm: "Accountant name",
        acclgn: "Accountant login",
        acclgnpsswrd: "Accountant login password",

        biev: "Welcome",
        selecrt: "Message Sending Interface",
        chxha: "Choose a real and a fake profile",
        chxF: "List of real profiles",
        chxV: "List of fake profiles",
        nomd: "Name",
        langs: "Language",
        errdj: " Select a real and a fake profile",
        plch: "Enter your message",
        pokePh: "Poke Messages (not yet sent)",
        teaserPh: "List of hook messages (not yet sent)",
        inter: "TextModing Test Interface",
        interSel: "Please select the tasks you want to perform",
        des1: "Sending a personalized message to a profile.",
        des2: "Sending a message to catch the profile's attention.",
        des3: "Personalized sending of engaging messages to new sign-ups to start conversations.",
        des4: "Sending a message to restart the conversation.",
        tit1: "Send message",
        tit2: "Teaser message",
        tit3: "Poke message",
        tit4: "Stop message",
        tache: "Task",
        bienvTest: "Welcome to the test interface of",
        enterEP: "Enter your email and password",
        instTest: "Instructions",
        sendInt: "Sending Interface",
        unPlP: "Choose one or more real profiles.",
        unNouv: "New",
        unReç: "Received",
        unNouv1: "New",
        payTot: "Total payment",
        allou: "Already allocated",
        Nallou: "Not yet allocated",
        totSoum: "Total message",
        Soum: "Submitted to QC",
        nonSoum: "Not submitted to QC",
        boot: "Boot",
        rvr: "Appointments/Meetings/Vacations",
        banit: "Banned",
        suspt: "Suspended",
        aucunt: "No message",
        msgstop: "Please write a stop message",
        messrt: "Message Rate",
        pkmessrt: "Poke Message Rate",
        tsrmessrt: "Teaser Message Rate",
        stpmessrt: "Stop Message Rate",
        bnsrt: "Bonus Rate",
        mesdes: "Sending messages on a dating site is crucial to capture the other person's attention and spark their interest. The key is to stay authentic, show genuine interest, and ask open-ended questions that encourage a response.",
        mesdes1: "Among the profiles, choose one real and one fake to send a message.",
        mesdes2: "Make sure to choose the appropriate correspondence language.",
        mesdes3: "Write your test message.",
        mesdes4: "You have 5 minutes to reply to the message on the operators' animation platform.",
        mesdes5: "Check your stats on your administrator dashboard.",
        stopdes: "Follow-up messages on a dating site are meant to rekindle interest and re-engage the conversation after a period of silence.",
        stopdes1: "Choose one real and one fake profile",
        stopdes2: "Draft a usual message",
        stopdes3: "Wait 24 hours after the chat operator has replied to the message from the real profile",
        stopdes4: "After 24 hours, the message not replied to by the real profile turns into a Follow-up Message, which the operator will compose and send.",
        stopdes5: "Check your stats on your administrator dashboard",
        teaserdes: "Teaser messages on a dating site are crucial for grabbing attention and sparking the other person's interest. The key is to stay authentic, show genuine interest, and ask open-ended questions that encourage a response",
        teaserdes1: "Among the profiles, choose one real and one fake to send a teaser message",
        teaserdes2: "Make sure to choose the appropriate correspondence language",
        teaserdes3: "On the animation platform for operators, draft the teaser messages",
        teaserdes5: "Check your stats on your administrator dashboard",
        pokedes: "Poke messages for a dating site are short and light messages designed to subtly and engagingly grab attention.",
        pokedes1: "Choose the country and language of the real profiles with which you want to send Poke messages",
        pokedes2: "Select one or more profiles with which you want to send Poke messages",
        pokedes3: "Check your stats on your administrator dashboard",
        stopbla: "List of follow-up messages already sent",
        respVp: "Response from the real profile",
        pasEn: "not yet.",
        notifAccr: "Test Hook Message: Profile selection made successfully",
        notifPoke: "Test Poke Message: Profile selection made successfully",
        pokeErr: "Click one or more real profiles",
        teaserErr: "click a fake and a real profile",
        reqGift : "Request gifts",
        reqGift1 : "Type of gift",
        reqGift2 : "Gift request",
    },


    { // it

        usrsd : "Lato utente",
        btsd : "Lato bot",
        nttkn : "Note prese",
        mssdnt : "Note mancate",
        gfts : "Regali",
        rqsts : "Richieste",
        messonhldallctd : "Messaggio in sospeso già assegnato",
        messonhldntallctd : "Messaggio in sospeso non assegnato",
        totnummess : "Numero totale di messaggi",
        messpssdqc : "Messaggi passati il controllo qualità",
        messntpssqc : "Messaggi non passati il controllo qualità",
        paymnts : "Pagamenti",
        bnk : "Banca",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Ricevuto",
        envoyees: "Inviato",
        dmndees: "Richiesto",
        nvaux: "Nuovo",

        adddest: "Indirizzo e-mail del destinatario",

        cmpn: "Azienda",
        tmfld: "Cartella di modifica del testo",
        accar: "Area di accesso",
        assvldt: "Validità di accesso",

        acc8: "Impostazione tariffa e bonus",

        mtng: "Riunione",
        rp: "Real Profil",
        fp: "Profilo falso",
        infopernoper: "Informazioni permanenti/non permanenti",
        dltnt: "Cancella nota",
        dltmesspnc: "Vuoi davvero cancellare questo messaggio di panico?",
        convbnnd: "Conversazione vietata",
        suspconv: "Sospendere la conversazione",
        upldd: "Caricato",
        addpht: "Aggiungi foto",
        phtsnttofp: "Elenco delle foto inviate ai profili falsi",
        pkmesscrtd: "Messaggio Poke creato con successo",
        updt: "Aggiornamento",
        dltpkmess: "Vuoi davvero cancellare questo messaggio poke?",
        plt: "Piattaforma",
        dltdmd: "Vuoi davvero cancellare questa richiesta? ",
        agntinfrmd: "Agente informato",
        dlttsrmess: "Vuoi davvero cancellare questo messaggio teaser?",
        updttsrmess: "Aggiorna il messaggio teaser",

        updtdsucc: "Aggiornato con successo",
        nonotif: "Nessuna notifica",
        continfo: "Informazioni sul contenuto",
        exp: "Mittente",
        recep: "Ricevitore",
        mdpupdtd: "Password aggiornata con successo",
        opsusp: "Operatore sospeso con successo",
        opdec: "Operatore disconnesso con successo",
        decop: "Si vuole veramente disconnettere questo operatore:  ",
        frop: "Si vuole veramente licenziare questo operatore:  ",
        chngmdp: "Cambia password",
        suspop: "Vuoi veramente sospendere questo operatore: ",
        onhld: "In attesa",
        supprmess: "Cancella questo messaggio",
        messinfo: "Informazioni sul messaggio",
        vld: "Conferma",
        ok: "Ok",
        enc: "In corso...",

        updtmdp: "Vuoi cambiare la password?",
        nwmdp: "Inserire la nuova password",
        dltsup: "Vuoi davvero cancellare questo supervisore?",
        ntsup: "Nota per un supervisore",
        nthr: "Nota qui...",
        dltimg: "Vuoi davvero cancellare questa immagine?",
        phttsnd: "Foto da inviare",
        vd: "Vuoto (selezionare una foto)",
        addd: "Aggiunta",
        nwrdv: "Aggiungere una nuova informazione",
        cnfrm: "Conferma",
        dltrdv: "Volete davvero cancellare questo Rendez-vous/ Riunioni/ Vacanze?",
        addnonperminfo: "Aggiungi informazioni non permanenti",
        dltinfo: "Vuoi davvero cancellare questa informazione?",
        idd: "ID",
        addperminfo: "Aggiungi informazioni permanenti",
        messreport: "Messaggio segnalato al supervisore",
        usrnm: "Nome utente",

        pp11 : "Creativ'Network, una società di intermediazione professionale per lavori web, con sede legale in 71-75 Shelton Street, Covent Garden, Londra, WC2H 9JQ, e con ufficio di rappresentanza in Roodborstjesstraat 4, Wondelgem 9032, di seguito denominata « la Società »,",
        pp12 : "E:",
        pp13 : "Lavoratore autonomo, che, in virtù del contratto di Lavoratore Autonomo che lo lega a « La Società » utilizza i materiali e le proprietà intellettuali di quest'ultima, di seguito denominato « il Lavoratore Autonomo »,",
        pp14 : "Di seguito denominati singolarmente « la Parte » e collettivamente « le Parti »,",
        pp21 : "La Società gestisce una piattaforma di animazione di siti di incontri di cui è l'unica proprietaria. La gestione operativa di tale piattaforma è affidata a operatori di chat, supervisori, team leader, controllori della qualità, impiegati come lavoratori autonomi dalla Società.",
        pp22 : "Nel contesto di questa relazione professionale, il Lavoratore Autonomo avrà accesso a informazioni riservate relative alla piattaforma, agli utenti dei siti di incontri, ai processi operativi, nonché a qualsiasi altra informazione protetta appartenente alla Società.",
        pp23 : "Detto ciò, si conviene quanto segue:",
        pp31 : "Il presente accordo di riservatezza ha lo scopo di proteggere le informazioni riservate che la Società potrebbe comunicare al Lavoratore Autonomo nell'ambito delle sue funzioni relative alla gestione della piattaforma di animazione di siti di incontri.",
        pp41 : "Ai fini del presente accordo, le « Informazioni Riservate » si riferiscono a tutte le informazioni, in qualsiasi forma (scritta, orale, elettronica, ecc.), relative alla piattaforma di animazione di siti di incontri, agli utenti, alle strategie di sviluppo, ai processi tecnici e operativi, ai dati finanziari, nonché a qualsiasi altra informazione designata come riservata dalla Società, che il Lavoratore Autonomo potrebbe ricevere o avere accesso nell'ambito del suo lavoro.",
        pp51 : "Il Lavoratore Autonomo si impegna a:",
        pp52 : "- Non divulgare, riprodurre, trasmettere o rendere accessibili le Informazioni Riservate, in tutto o in parte, a terzi, senza l'autorizzazione scritta preventiva della Società.",
        pp53 : "- Utilizzare le Informazioni Riservate solo nel contesto delle attività relative alla gestione della piattaforma e solo nell'interesse della Società.",
        pp54 : "- Adottare tutte le misure necessarie per proteggere la riservatezza delle Informazioni Riservate e prevenire qualsiasi uso non autorizzato.",
        pp55 : "- Restituire alla Società, su richiesta, qualsiasi documento o supporto contenente Informazioni Riservate, senza conservarne copie, in qualsiasi forma.",
        pp61 : "Il presente accordo entra in vigore al momento in cui il lavoratore accetta durante l'identificazione per l'accesso alla piattaforma di animazione e rimarrà in vigore per tutta la durata della relazione professionale tra la Società e il Lavoratore Autonomo. Gli obblighi di riservatezza continueranno per un periodo di 10 anni dopo la conclusione della suddetta relazione, indipendentemente dalla causa.",
        pp71 : "Non sono considerate Informazioni Riservate:",
        pp72 : "- Le informazioni che erano già conosciute dal pubblico al momento della loro comunicazione al Lavoratore Autonomo, o che diventeranno pubbliche in altro modo rispetto alla violazione del presente accordo.",
        pp73 : "- Le informazioni che il Lavoratore Autonomo può dimostrare di aver ricevuto legittimamente da un terzo non vincolato da un obbligo di riservatezza.",
        pp74 : "- Le informazioni sviluppate in modo indipendente dal Lavoratore Autonomo senza l'uso delle Informazioni Riservate.",
        pp81 : "In caso di violazione degli obblighi previsti dal presente accordo, il Lavoratore Autonomo riconosce che la Società potrà intraprendere tutte le azioni legali necessarie per proteggere i suoi diritti e ottenere risarcimento per i danni subiti.",
        pp91 : "Il presente accordo è regolato dalla legge belga. In caso di controversia relativa all'interpretazione o all'esecuzione del presente accordo, le Parti si impegnano a cercare una soluzione amichevole. In assenza di accordo amichevole, i tribunali competenti di Gand saranno gli unici competenti.",
        pp1 : "Tra le Parti",
        pp2 : "Premessa",
        pp3 : "Articolo 1: Oggetto",
        pp4 : "Articolo 2: Definizione delle Informazioni Riservate",
        pp5 : "Articolo 3: Obblighi del Lavoratore Autonomo",
        pp6 : "Articolo 4: Durata",
        pp7 : "Articolo 5: Esclusioni",
        pp8 : "Articolo 6: Disposizioni Generali",
        pp9 : "Articolo 7: Legge Applicabile e Giurisdizione Competente",
        paymail: 'Indirizzo e-mail di pagamento',
        scc: 'Creato',
        login: 'Login',
        mail:'Email',
        password: 'Password',
        confirmpass:'Conferma la password',
        alreadyreg:'Già registrato? Login',
        notifs: "Notifiche",
        limite:'Limitato',
        save:'Salvare',
        archives:'Archivi',
        ttlmsg:'Totale Messaggi',
        controlLog:'Login del Controllore',
        nbrmess:'Numero di messaggi',
        appQC:'Apprezzamenti del QC',
        esqmq:'Schivata mancante',
        dbspace:'Doppio spazio',
        notelu:'Nota letta',
        actpris:'Azione intrapresa',
        ferme:'Chiuso',
        losn:'Elenco delle note inviate',
        lorn:'Elenco delle note ricevute',
        validity:'Validità',
        editInfo:"Modificare un'informazione",
        controlID:'Controllore ID',
        statcontrolct:'Statistiche di controllo per controllore',
        statcontrolag:'Statistiche di controllo per Agente',
        statcontrol:'Statistiche di controllo',
        traitement:'Trattamento',
        agentid:'ID agente',
        statconvatt:'Statistiche delle Conversazioni in Attesa',

        propesqu:'Inserisci una scusa per una proposta di appuntamento',
        msgacc:'Si prega di scrivere un messaggio accattivante',
        msgpoke:'Si prega di scrivere un messaggio Poke',
        heure:'Ora',
        esqv:'Schivata',
        esquive:'Schivate incontri e appuntamenti',
        other:'Altri',
        sexe:'Sesso',
        bannir:'Bandire',
        agntLgn:'Agente Online',
        features:'Funzionalità',
        rdv:'Appuntamento',
        holiday:'Vacanza',
        valider:'Convalidare',
        panicmsg:'questo messaggio di panico?',
        jour:'Giorno',
        jours:'Giorni',
        conversation:'Conversazione',
        profil:'Profilo',
        suspendre:'Sospendere',
        expulser:'Espellere',
        information:'Informazione',
        meeting:'Riunione',
        online:'Online',
        onHold:'Behandelt',
        treated:'Trattato',
        listPhotoSend:'Elenco di foto inviate',
        agentReport:'Agente segnalato',
        suspendu:'Sospeso',
        
        etat:'Stato',
        deconnecter:'Disconnettere',
        actqc4: 'Messaggio convalidato',
        panicmess:'Messaggio di panico',
        contenu:'Contenuto',
        raison:'Ragione',
        reportmess:'Segnala un messaggio',
        language1:'Lingua',
        reg:'Registrati',
        enreg:['Non sei ancora registrato?','Registrati'],
        passdiff:'Password diversa',
        qlogout: 'Vuoi uscire?',
        annuler:'Annulla',
        addnote: "Nota aggiunta",
        notesuppr: 'Nota eliminata',
        editer:'Modificare',
        editNote:'Modifica Nota',
        logout: 'Disconnettersi',
        yes: 'Sì',
        no: 'No',

        operatorpage: "Pagina operatore",
        supervisorpage: "Pagina dei supervisori",
        adminpage: "Pagina degli amministratori",

        required: "Questo campo è obbligatorio",
        invalid: "Email non valida",
        registered: "Questo indirizzo e-mail non è registrato",
        incorrectpass: "Password non corretta",
        atleast: "La password deve contenere almeno",
        characters: "caratteri",

        convman: "Gestione delle conversazioni",
        agman: "Gestione degli agenti",
        notesman: "Gestione delle note",
        agrep: "Rapporti sugli agenti",
        gftgtfman: "Gestione dei regali e degli omaggi",
        tmfldaa: "Permesso di accesso alla cartella TextModing",
        hlp: "Aiuto",
        pltflng: "Lingua della piattaforma",
        pr: "Richieste di foto",
        wlcm: "Benvenuti",
        wlcmtxt: "TextModing è una piattaforma di animazione progettata per i siti di incontri, che offre funzionalità essenziali per la gestione delle conversazioni e degli agenti. Migliora l'efficienza e la redditività dei partner fornendo strumenti che semplificano le interazioni e ottimizzano il coinvolgimento degli utenti.",

        messageto: "Messaggio a",
        convatt: "Conversazione in attesa",
        mincharlen: "Almeno 120 caratteri",
        char: "carattere",
        chars: "caratteri",
        pj: "Foto allegate",
        writemessage: 'Scrivi un messaggio...',
        picalreadysent: "Foto vista dall'utente",
        gifpj: 'GIFS',
        opengif: 'Apri GIF',
        sendMessage: 'Invia il messaggio',
        reportSup: 'Inviare una nota al supervisore',
        conv: 'ID conversazione',
        say: 'dice',
        timeIntro: 'at',
        elements: 'elementi',
        element: 'elemento',
        none: 'Nessuno',

        stat: 'Statistics',
        french: 'Francese',
        english: 'Inglese',
        italian: 'Italiano',
        dutch: 'Olandese',
        spanish: 'Spagnolo',
        german: 'Tedesco',
        botprofil: 'Profilo Bot',
        userprofil: "Profilo utente",
        otherInfo: "Altre informazioni importanti",
        codepost: 'Codice postale',
        ville: "Città",
        region: "Regione",
        pays: 'Paese',
        taille: 'Taglia',
        eyes: 'Colore degli occhi',
        hair: 'Colore dei capelli',
        morph: 'Morfologia',
        situation: "Stato civile",
        searchfor: 'Cerca per',
        job: "Occupazione",
        signe: "Segni distintivi",
        descri: "Descrizione",
        infosupp: 'Informazioni aggiuntive',
        supervisiontchat: 'Supervisione della chat',
        statconvop: 'Conversazione tra statisti e operatori',
        convs: 'Conversazioni',
        report: 'Rapporto',
        agentInform:'Agente informato',
        events: 'Evento',
        photosrequests: 'Richiesta di foto',
        warmup: 'Warmup',
        gifattch: 'Allegato Gif',
        fakeprofiles: 'Profili falsi',
        pagenotfound: 'Pagina non trovata',
        suivvraiprof: 'Monitoraggio dei profili reali',
        suiviop: "Monitoraggio degli operatori",
        admintion: 'Amministrazione',
        affiliation: 'Affiliazione',

        operator: 'Operatore',
        supervisor: 'Supervisore',
        administrator: 'Amministratore',
        week: 'settimana',
        weeks: 'settimane',
        today: 'Oggi',

        start: 'Inizio',
        end: 'Fine',
        platform: 'Piattaforme',
        search: 'Ricerca',
        go: 'Vai',
        allmasc: 'Tutti',
        allfem: 'Tutti',

        id: 'Identificatore',
        opname: "Nome dell'operatore",
        sentmess: "Messaggi inviati",
        recmess: "Messaggi ricevuti",
        relmess: "Messaggi in sospeso",

        date: "Data",
        messid: "Identificatore del messaggio",
        convid: "Identificatore della conversazione",
        exped: "Mittente",
        recpt: 'Ricevitore',
        opsign: "Operatore da segnalare",
        opexped: 'Operatore da inviare',
        action: 'Azione',
        messs: 'Messaggi',
        filterdate: 'Filtra le ricerche tra due date',
        filtermess: 'Filtra le conversazioni per messaggio',
        from: 'Da',
        to: 'a',
        eventList: 'Elenco eventi',
        num: 'Numero',
        eventSearch: 'Cerca un evento',
        actions: 'Azioni',
        eventname: "Nome dell'evento",
        eventdate: "Data dell'evento",
        uploadimage: "Carica immagini",
        role: "Ruolo",
        logIn: "Log in",
        ppaccept1: "Accedendo alla nostra piattaforma, confermate di aver letto le nostre",
        ppaccept2: "e di aver seguito la formazione adeguata per svolgere il suo incarico",
        pp: "accordo di riservatezza",
        copyright: "© 2023-2024 Copyright TextModing® - Tutti i diritti riservati",
        accountant: "contabile",
        qualitycontroller: "controllore della qualità",
        adminid: "ID amministratore",
        accountantid: "ID contabile",
        operatorid: "ID operatore",
        qcid: "ID controllore qualità",
        supervisorid: "ID supervisore",
        convonhold: "Conversazioni in attesa",
        supdash: "Cruscotto del supervisore",
        choverv: "Panoramica della chat",
        months: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
        year: 'Anno',
        month: 'Mese',
        startingdate: 'Data di inizio',
        closingdate: 'Data di chiusura',

        agents: "Agenti",
        out: "Uscite",
        in: "Ingressi",
        details: "Dettagli",
        panicroom: "Stanza del panico",
        notes: "Note",
        teasermessages: "Messaggi teaser",
        pokemessages: "Messaggi Poke",
        attdisc:'Attesa di Discussione',
        gifajt:'Gif Aggiungi',
        stopmessages: "Messaggi di stop",
        sent: "Inviato",
        asnwered: "Risposto",
        requested: "Richiesto",
        logbook: "Diario",
        recorded: "Registrato",
        notrecorded: "Non registrato",
        duplicatedmessages: "Messaggi duplicati",
        back: "Indietro",
        suty: "Tipo di abbonamento",
        ag: "Età",
        gend: "Sesso",
        sexor: "Orientamento sessuale",
        tow: "Città",
        
        users: "Utenti",
        sender: "Mittente",
        receiver: "Ricevitore",
        agent: "Agente",
        status: "Stato",
        read: "Lettura",
        active: "Attivo",
        edit: "Modifica",
        user: "Utente",
        sendnote: "Invia una nota",
        oh: "In attesa",
        mess: "Messaggio",
        trconv: "Conversazioni trattate",
        idconv: "ID conversazione",
        idmess: "ID messaggio",
        otherimp: "Altre informazioni importanti",
        otherimp1: "Riunione/ Incontri/ Vacanze",
        otherimp2: "Informazioni permanenti",
        otherimp3: "Informazioni utili, non permanenti",
        
        aglist: "Elenco agenti",
        pfsperf: "Prestazioni della piattaforma",
        agperf: "Prestazioni degli agenti",
        nwag: "Aggiungi nuovi agenti",
        agname: "Nome agente",
        agcountry: "Paese dell'agente",
        aglogin: "Accesso agente",
        agloginpw: "Password agente",
        langpf: "Piattaforma linguistica",
        onl: "Online",
        offl: "Offline",
        suspend: "Sospensione",
        koff: "Reinvio",
        grotot: "Totale lordo",
        agnumbewoon: "Numero di profili falsi",
        totsenmess: "Messaggi totali inviati",
        totrecmess: "Totale messaggi ricevuti",
        totrep: "Totale rapporti",
        real: "Nome reale",
        prov: "Provincia",
        weight: "Peso",
        child: "Bambini",
        time: "Tempo",
        fstnm: "Nome",
        lstnm: "Cognome",
        dtofbir: "Data di nascita",
        skypeid: "ID Skype",
        crnwag: "Crea nuovo agente",
        usrprfl: "Profilo utente",
        sndmess: "Invia messaggio",
        blckusr: "Blocca utente",
        lstnts: "Elenco di note",
        crtsndnts: "Crea/Invia una nota",
        evnt: "Evento",
        ntid: "ID nota",
        nt: "Nota",
        dlt: "Elimina",
        cndid: "ID candidato",
        language: "Lingua",
        perc1: "Avete composto il ",
        perc2: " del messaggio dell'utente",
        giftsatt: "Regali allegati",
        sndnt: "Invia la nota",
        lsttsrmsg: "Elenco dei messaggi teaser",
        crttsrmsg: "Crea messaggi teaser",
        idtsrmsg: "ID del messaggio teaser",
        tsrmsg: "Messaggio teaser",
        dlvrdt: "Consegnato a",
        wiseg:"Gif sagge",
        sexg:"Gif sessuali",
        gayg:"Gif gay",
        Lesbg: "Gif lesbiche",
         transg:"Gif transgender",
        travg:"Gif travestite",
                
        bdsm:"BDSM",
        dont: "Non essere lungo",
        blj:"Pompino",
        org:"Orgasmo",
        vagpen:"Penetrazione vaginale",
        sod:"Sodomia",
        titss:"Tette",

        bdsmg: "BDSM Gay",
        wisegg: "Gif Gay Saggi",
        sexgg:"Gif di sesso gay",

        bdsml: "BDSM Lesbica",
        wiselg:"Gif lesbiche sagge",
        sexlg:"Gif di sesso lesbico",

        maletrans:"Maschio transgender",
        femaletrans:"Transgender femmina",
        lstpkmsg: "Elenco dei messaggi poke",
        crtpkmsg: "Crea messaggio poke",
        idpkmsg: "ID del messaggio poke",
        pkmsg: "Messaggio poke",
        lststpmsg: "Elenco dei messaggi di arresto",
        idstpmsg: "ID messaggio di stop",
        stpmsg: "Messaggio di arresto",
        sntto: "Inviato a",
        agntwrtngstp: "Agente che scrive l'arresto",
        dtofdlvr: "Data di consegna",
        dtofans: "Data della risposta",
        stpperfanal: "Interrompere l'analisi delle prestazioni",
        stpgrssttl: "Arresto del totale lordo",
        rsptostp: "Risposta all'arresto",
        rsptostp2: "Risposta all'Aggancio",
        rsptostp3: "Risposta al Poke",
        stpprcntresp: "Risposta in % all'arresto",
        avrgrsptm: "Tempo medio di risposta",

        lstphtrq: "Richiesta elenco foto",
        phttp: "Tipo di foto",
        idrq: "ID richiesta",
        pht: "Foto",
        upld: "Upload",
        snd: "Invia",
        lstrprt: "Elenco dei rapporti",
        rpttp: "Tipo di rapporto",
        idrprt: "ID rapporto",
        msgrprtd: "Messaggio riportato",
        infrmagnt: "Agente informativo",
        lstlgbk: "Elenco del registro",
        lstmssnglgbk: "Elenco dei registri mancanti",
        lgbktp: "Tipo di registro",
        idlgbk: "ID del giornale di bordo",
        infs: "Informazioni",
        lvlofimprt: "Livello di importanza",
        prmnnt: "Permanente",
        ntprmnnt: "Non permanente",
        mssnglgbk: "Registro mancante",
        mrklgbk: "Contrassegnare il registro",
        
        dh: "Data e ora",
        mid: "ID messaggio",
        repmess: "Segnala questo messaggio",
        bts: "Bot dice",
        obj: "Oggetto",
        plc: "Luogo",

        lstdplmss: "Elenco dei messaggi duplicati",
        iddplmss: "ID dei messaggi duplicati",
        lstgfs: "Elenco di GIFs",
        ddgfs: "Aggiungi nuova GIF",
        gftp: "Tipo di GIF",
        gfimg: "Immagine GIF",

        nat: "Natura",
        tmslt: "Slot temporale",
        gfsnt: "GIF inviate",

        qcdash: "Cruscotto del controllore di qualità",
        qctrl: "Controllo qualità",
        lstmess: "Elenco dei messaggi",
        qcpnl: "Canale controllo qualità",
        ptreq: "Richiesta foto",
        sp: "Invia foto",
        sgft: "Invia regalo",
        sgif: "Invia Gif",
        qccrit: "Criteri QC",
        qccrit1: "Chat buona",
        qccrit2: "Chat scadente",
        qccrit3: "Profilo utente considerato",
        qccrit4: "Profilo bot considerato",
        qccrit5: "Conversazione affrontata dal bot",
        qccrit6: "CTA da parte dell'agente",
        qccrit7: "Conversazione senza senso",
        qccrit8: "Utente insoddisfatto del bot",
        qccrit9:'Nota ben presa',
        actqc1: "Informare l'agente",
        actqc2: "Sospendere l'agente",
        actqc3: "Licenziare l'agente",
        adc1:'Agente eccellente',
        adc2:'Agente medio',
        adc3:'Agente mediocre',
        sndmailtoadm: "Invia un'e-mail all'amministratore",
        yrnm: "Il vostro nome",
        yrcmpnm: "Il nome della vostra azienda",
        yrmladdr: "Il vostro indirizzo e-mail",
        yradmid: "ID amministratore",
        objctfyrrqst: "Oggetto della richiesta",
        yrmss: "Il vostro messaggio",
        yrattchmt: "Allegato",
        sndthml: "Invia l'e-mail",
        ddnwadm: "Aggiungi un nuovo amministratore",
        nm: "Nome",
        mladd: "Indirizzo e-mail",
        cmpnnm: "Nome della società",
        tmfldr: "Cartella TextModing",
        psswrd: "Password",
        accssar: "Aree di accesso",
        valdt: "Validità",
        rgstr: "Registro",

        accdash: "Cruscotto contabile",
        acc: "Conti",
        acc1: "Statistiche agenti",
        acc2: "Pagamento con bonifico",
        acc3: "Pagamento con Paypal",
        acc4: "Pagamento tramite saggio",
        acc5: "Pagamento con Yoursafe",
        acc6: "Aggiungi un contabile",

        admdash: "Cruscotto amministratore",
        chttlov: "Panoramica Chattool",
        suptraffman: "Supervisione e gestione del traffico",
        vw: "Vedere",
        
        ratepermess: "Tariffa per messaggio",
        bonus: "Bonus",
        totpay: "Pagamento totale",
        paymeth: "Metodo di pagamento",
        grosstot: "Totale lordo al mese di ",
        prstat: "Stampa dell'estratto conto",
        agfn: "Nome agente",
        agln: "Cognome dell'agente",
        payadd: "Indirizzo e-mail Paypal",
        nysent: "Non ancora inviato",
        wiseadd: "Indirizzo e-mail di Wise",
        yoursafeadd: "Indirizzo e-mail di Yoursafe",
        bankn: "Nome della banca",
        iban: "IBAN",
        bic: "Codice BIC",

        lstsup: "Elenco dei supervisori",
        ddnwsup: "Aggiungi un nuovo supervisore",
        trffcmng: "Gestione del traffico",
        supnm: "Nome del supervisore",
        suplgn: "Connessione supervisore",
        suplgnpsswrd: "Password di accesso al supervisore",
        idcnddt: "ID candidato",
        cntry: "Paese",
        lgn: "Accesso",
        crtnwsup: "Creazione di un nuovo supervisore",
        fragntonln: "Agente libero online",
        msgalltag: "Messaggio assegnato all'agente",
        allct: "Assegnazione",
        addqc: "Aggiungi controllore qualità",
        crtqc: "Crea controllore qualità",
        crtnwacc: "Creare un nuovo contabile",

        gifn: "GIF da inviare",
        restfn: "Reset",
        gifsel: "Vuoto (selezionare GIF)",
        alerttxt: "GIF inviati con successo.",
        option: "opzioni",

        phalert: "Richiesta di foto inviata.",
        phtitre: "Richiesta di foto",
        phlabel: "Tipo di foto",
        phsend: "Invia",
        phload: "Caricamento...",

        delalert: "Soppresso",
        deltitre1: "Vuoi davvero eliminare questa ",
        deltitre2: "richiesta ?",
        delauteur: "Autore",
        deldesc: "Descrizione",
        delaccept: "Sì",
        delrefuse: "No",
        delload: "Caricare..",

        lstqc: "Elenco dei controllori della qualità",
        qcnm: "Nome del controllore della qualità",
        qclgn: "Login controllore qualità",
        qclgnpsswrd: "Password di accesso del controllore della qualità",
        lstacc: "Elenco dei contabili",
        accnm: "Nome del contabile",
        acclgn: "Login del contabile",
        acclgnpsswrd: "Password di accesso del contabile",

        biev: "Benvenuto / Benvenuta",
        selecrt: "Interfaccia di invio messaggi",
        chxha: "Scegli un profilo vero e uno falso",
        chxF: "Elenco dei profili veri",
        chxV: "Elenco dei profili falsi",
        nomd: "Nome",
        langs: "Lingua",
        errdj: "Seleziona un profilo vero e uno falso",
        plch: "Inserisci il tuo messaggio",
        pokePh: "Messaggi Poke (non ancora inviati)",
        teaserPh: "Elenco dei messaggi di aggancio (non ancora inviati)",
        inter: "Interfaccia di test di TextModing",
        interSel: "Si prega di selezionare le attività che si desidera eseguire",
        des1: "Invio di un messaggio personalizzato a un profilo.",
        des2: "Invio di un messaggio per attirare l'attenzione del profilo.",
        des3: "Invio personalizzato di messaggi accattivanti ai nuovi iscritti per avviare conversazioni.",
        des4: "Invio di un messaggio per riavviare la conversazione.",
        tit1: "Invio messaggio",
        tit2: "Messaggio teaser",
        tit3: "Messaggio poke",
        tit4: "Messaggio di arresto",
        tache: "Compito",
        bienvTest: "Benvenuto nell'interfaccia di test di",
        enterEP: "Inserisci la tua email e password",
        instTest: "Istruzioni",
        sendInt: "Interfaccia di invio",
        unPlP: "Scegli uno o più profili reali.",
        unNouv: "Nuova",
        unReç: "Ricevute",
        unNouv1: "Nuovo",
        payTot: "Totale pagamento",
        allou: "Già allocati",
        Nallou: "Non ancora assegnati",
        totSoum: "Messaggio totale",
        Soum: "Inviato a QC",
        nonSoum: "Non inviato a QC",
        boot: "Boot",
        rvr: "Appuntamenti/Riunioni/Vacanze",
        banit: "Bandito",
        suspt: "Sospeso",
        aucunt: "Nessun messaggio",
        msgstop: "Per favore scrivi un messaggio di follow-up",
        messrt: "Tasso di messaggio",
        pkmessrt: "Tasso di poke message",
        tsrmessrt: "Tasso di messaggio teaser",
        stpmessrt: "Tasso di messaggio di stop",
        bnsrt: "Tasso di bonus",
        mesdes: "Inviare messaggi su un sito di incontri è fondamentale per catturare l'attenzione e suscitare l'interesse dell'altra persona. L'importante è rimanere autentici, mostrare un interesse sincero e porre domande aperte che incoraggino una risposta.",
        mesdes1: "Tra i profili, scegli uno vero e uno falso per inviare un messaggio.",
        mesdes2: "Assicurati di scegliere la lingua di corrispondenza corretta.",
        mesdes3: "Scrivi il tuo messaggio di prova.",
        mesdes4: "Hai 5 minuti per rispondere al messaggio sulla piattaforma di animazione per gli operatori.",
        mesdes5: "Verifica le tue statistiche nel tuo pannello di amministrazione.",
        stopdes: "I messaggi di sollecito su un sito di incontri servono a ravvivare l'interesse e a rilanciare la conversazione dopo un periodo di silenzio.",
        stopdes1: "Scegli un profilo reale e uno falso",
        stopdes2: "Redigi un messaggio abituale",
        stopdes3: "Aspetta 24 ore dopo che l'operatore della chat ha risposto al messaggio del profilo reale",
        stopdes4: "Dopo 24 ore, il messaggio non risposto dal profilo reale si trasforma in un Messaggio di sollecito, che l'operatore si occuperà di redigere e inviare.",
        stopdes5: "Controlla le tue statistiche nel tuo pannello di amministrazione",
        teaserdes: "I messaggi di accattivamento su un sito di incontri sono cruciali per attirare l'attenzione e suscitare l'interesse dell'altra persona. È fondamentale rimanere autentici, mostrare un interesse sincero e fare domande aperte che incoraggiano una risposta",
        teaserdes1: "Tra i profili, scegli uno reale e uno falso per inviare un messaggio di accattivamento",
        teaserdes2: "Assicurati di scegliere la lingua di corrispondenza corretta",
        teaserdes3: "Sulla piattaforma di animazione per gli operatori, redigi i messaggi di accattivamento",
        teaserdes5: "Controlla le tue statistiche nel tuo pannello di amministrazione",
        pokedes: "I messaggi 'Poke' su un sito di incontri sono messaggi brevi e leggeri, progettati per attirare l'attenzione in modo sottile e coinvolgente.",
        pokedes1: "Scegli il paese e la lingua dei profili reali a cui vuoi inviare messaggi Poke",
        pokedes2: "Seleziona uno o più profili a cui vuoi inviare messaggi Poke",
        pokedes3: "Controlla le tue statistiche nel tuo pannello di amministrazione",
        stopbla: "Elenco dei messaggi di follow-up già inviati",
        respVp: "Risposta dal profilo reale",
        pasEn: "non ancora.",
        notifAccr: "Messaggio di aggancio di prova: Selezione profilo effettuata con successo",
        notifPoke: "Messaggio Poke di prova: Selezione profilo effettuata con successo",
        pokeErr: "Clicca su uno o più profili reali",
        teaserErr: "clicca su un profilo falso e uno vero",
        reqGift : "Richiedi regali",
        reqGift1 : "Tipo di regalo",
        reqGift2 : "Richiesta di regalo",
    },


    { // nl

        usrsd : "Gebruikerszijde",
        btsd : "Botzijde",
        nttkn : "Genomen notities",
        mssdnt : "Gemiste notities",
        gfts : "Cadeaus",
        rqsts : "Verzoeken",
        messonhldallctd : "Bericht in afwachting al toegewezen",
        messonhldntallctd : "Bericht in afwachting niet toegewezen",
        totnummess : "Totaal aantal berichten",
        messpssdqc : "Berichten geslaagd voor QC",
        messntpssqc : "Berichten niet geslaagd voor QC",
        paymnts : "Betalingen",
        bnk : "Bank",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Ontvangen",
        envoyees: "Verzonden",
        dmndees: "Aangevraagd",
        nvaux: "Nieuw",

        adddest: "E-mailadres ontvanger",

        cmpn: "Bedrijf",
        tmfld: "Tekstmodemap",
        accar: "Toegangsgebied", 
        assvldt: "Toegangsgeldigheid",

        acc8: "Tarief en Bonus instellen",

        mtng: "Meeting",
        rp: "Echt profiel",
        fp: "Nepprofiel",
        infopernoper: "Permanente / niet-permanente informatie",
        dltnt: "Notitie verwijderen",
        dltmesspnc: "Wilt u dit paniekbericht echt verwijderen?",
        convbnnd: "Verbannen gesprek",
        suspconv: "Hang het gesprek op",
        upldd: "Geüpload",
        addpht: "Foto toevoegen",
        phtsnttofp: "Lijst met foto's verzonden naar nepprofielen",
        pkmesscrtd: "Poke-bericht succesvol aangemaakt",
        updt: "Bijwerken",
        dltpkmess: "Wil je dit poke bericht echt verwijderen?",
        plt: "Platform",
        dltdmd: "Wil je dit verzoek echt verwijderen? ",
        agntinfrmd: "Agent op de hoogte",
        dlttsrmess: "Wil je dit teaser bericht echt verwijderen?",
        updttsrmess: "Update teaser bericht",

        updtdsucc: "Met succes bijgewerkt",
        nonotif: "Geen melding",
        continfo: "Inhoudelijke informatie",
        exp: "Afzender",
        recep: "Ontvanger",
        mdpupdtd: "Wachtwoord succesvol bijgewerkt",
        opsusp: "Operator met succes opgeschort",
        opdec: "Operator met succes losgekoppeld",
        decop: "Wilt u deze operator echt loskoppelen:  ",
        frop: "Wil je deze operator echt ontslaan:  ",
        chngmdp: "Wijzig wachtwoord",
        suspop: "Wil je deze operator echt schorsen: ",
        onhld: "In de wacht",
        supprmess: "Verwijder dit bericht",
        messinfo: "Berichtinformatie",
        vld: "Bevestigen",
        ok: "Ok",
        enc: "Wordt aan gewerkt ...",

        updtmdp: "Wilt u het wachtwoord wijzigen?",
        nwmdp: "Voer nieuw wachtwoord in",
        dltsup: "Wil je deze supervisor echt verwijderen?",
        ntsup: "Notitie voor een supervisor",
        nthr: "Notitie hier...",
        dltimg: "Wil je deze foto echt verwijderen?",
        phttsnd: "Te versturen foto",
        vd: "Leeg (selecteer een foto)",
        addd: "Toegevoegd",
        nwrdv: "Nieuwe informatie toevoegen",
        cnfrm: "Bevestigen",
        dltrdv: "Wilt u deze Rendez-vous/ Vergaderingen/ Vakanties echt verwijderen?",
        addnonperminfo: "Voeg niet-permanente informatie toe",
        dltinfo: "Wilt u deze informatie echt verwijderen?",
        idd: "ID",
        addperminfo: "Permanente informatie toevoegen ",
        messreport: "Bericht gemeld aan supervisor",
        usrnm: "Gebruikersnaam",

        pp11 : "Creativ'Network, een bedrijf voor professionele bemiddeling voor webwerkzaamheden, gevestigd te 71-75 Shelton Street, Covent Garden, Londen, WC2H 9JQ, en met een vertegenwoordigingskantoor te Roodborstjesstraat 4, Wondelgem 9032, hierna aangeduid als « de Vennootschap »,",
        pp12 : "En:",
        pp13 : "Zelfstandige, die op grond van de Zelfstandige Overeenkomst die hem verbindt met « De Vennootschap » gebruik maakt van de materialen en intellectuele eigendommen van laatstgenoemde, hierna aangeduid als « de Zelfstandige »,",
        pp14 : "Hierna afzonderlijk aangeduid als « de Partij » en gezamenlijk als « de Partijen »,",
        pp21 : "De Vennootschap exploiteert een platform voor het beheer van datingsites waarvan zij de exclusieve eigenaar is. Het operationele beheer van dit platform is toevertrouwd aan chatoperators, supervisors, teamleiders, kwaliteitscontroleurs, die door de Vennootschap als zelfstandigen worden ingehuurd.",
        pp22 : "In het kader van deze professionele relatie zal de Zelfstandige toegang hebben tot vertrouwelijke informatie met betrekking tot het platform, de gebruikers van de datingsites, de operationele processen, evenals tot andere beschermde informatie die eigendom is van de Vennootschap.",
        pp23 : "Gezien het bovenstaande, wordt als volgt overeengekomen:",
        pp31 : "Deze geheimhoudingsovereenkomst heeft tot doel de vertrouwelijke informatie te beschermen die de Vennootschap mogelijk aan de Zelfstandige verstrekt in het kader van zijn taken met betrekking tot het beheer van het platform voor datingsites.",
        pp41 : "Voor de doeleinden van deze overeenkomst verwijzen de « Vertrouwelijke Informatie » naar alle informatie, in welke vorm dan ook (schriftelijk, mondeling, elektronisch, etc.), met betrekking tot het platform voor datingsites, de gebruikers, de ontwikkelingsstrategieën, de technische en operationele processen, de financiële gegevens, evenals alle andere informatie die door de Vennootschap als vertrouwelijk wordt aangeduid en die de Zelfstandige kan ontvangen of waartoe hij toegang heeft in het kader van zijn werk.",
        pp51 : "De Zelfstandige verbindt zich ertoe om:",
        pp52 : "- Geen Vertrouwelijke Informatie, geheel of gedeeltelijk, aan derden te onthullen, reproduceren, door te geven of toegankelijk te maken zonder voorafgaande schriftelijke toestemming van de Vennootschap.",
        pp53 : "- Vertrouwelijke Informatie alleen te gebruiken in het kader van de activiteiten die verband houden met het beheer van het platform en uitsluitend ten behoeve van de Vennootschap.",
        pp54 : "- Alle noodzakelijke maatregelen te nemen om de vertrouwelijkheid van de Vertrouwelijke Informatie te beschermen en ongeautoriseerd gebruik te voorkomen.",
        pp55 : "- Op verzoek van de Vennootschap alle documenten of materialen die Vertrouwelijke Informatie bevatten terug te geven, zonder daarvan kopieën te bewaren in welke vorm dan ook.",
        pp61 : "Deze overeenkomst treedt in werking op het moment dat de werknemer zijn akkoord geeft bij zijn identificatie voor toegang tot het platform voor animatie en blijft van kracht gedurende de gehele duur van de professionele relatie tussen de Vennootschap en de Zelfstandige. De geheimhoudingsverplichtingen blijven bestaan gedurende een periode van 10 jaar na beëindiging van de relatie, ongeacht de oorzaak.",
        pp71 : "De volgende worden niet beschouwd als Vertrouwelijke Informatie:",
        pp72 : "- Informatie die al publiekelijk bekend was op het moment van de communicatie aan de Zelfstandige, of die op andere wijze publiekelijk wordt, behalve door een schending van deze overeenkomst.",
        pp73 : "- Informatie die de Zelfstandige kan aantonen legaal te hebben ontvangen van een derde die niet gebonden is aan een geheimhoudingsverplichting.",
        pp74 : "- Informatie die onafhankelijk door de Zelfstandige is ontwikkeld zonder gebruik te maken van Vertrouwelijke Informatie.",
        pp81 : "In geval van een schending van de verplichtingen die in deze overeenkomst zijn vastgelegd, erkent de Zelfstandige dat de Vennootschap alle juridische stappen kan ondernemen die nodig zijn om haar rechten te beschermen en schadevergoeding te verkrijgen voor geleden schade.",
        pp91 : "Deze overeenkomst wordt beheerst door het Belgische recht. In geval van geschillen over de interpretatie of uitvoering van deze overeenkomst, verbinden de Partijen zich ertoe een minnelijke oplossing te zoeken. Bij gebrek aan een minnelijke oplossing zijn uitsluitend de bevoegde rechtbanken van Gent bevoegd.",
        pp1 : "Tussen de Partijen",
        pp2 : "Inleiding",
        pp3 : "Artikel 1: Doel",
        pp4 : "Artikel 2: Definitie van Vertrouwelijke Informatie",
        pp5 : "Artikel 3: Verplichtingen van de Zelfstandige",
        pp6 : "Artikel 4: Duur",
        pp7 : "Artikel 5: Uitzonderingen",
        pp8 : "Artikel 6: Algemene Bepalingen",
        pp9 : "Artikel 7: Toepasselijk Recht en Bevoegde Rechtbank",
        paymail: "E-mailadres voor betaling",
        scc: "Aangemaakt",
        login: 'Login',
        mail:'Email',
        reg:'Register',
        password: 'Wachtwoord',
        confirmpass:'Bekreft mot de pass',
        alreadyreg:'Allerede registrert? å logge inn',
        notifs: "Meldingen",
        limite:'Beperkt',
        save:'Opslaan',
        archives:'Archieven',
        ttlmsg:'Totaal Berichten',
        controlLog:'Controller-inlog',
        nbrmess:'Aantal berichten',
        appQC:'QC Waarderingen',
        esqmq:'Ontbrekende ontwijking',
        dbspace:'Dubbele spatie',
        notelu:'Notitie gelezen',
        actpris:'Actie ondernomen',
        ferme:'Gesloten',
        losn:'Lijst van verzonden notities',
        lorn:'Lijst van ontvangen notities',
        validity:'Geldigheid',
        editInfo:'Informatie wijzigen',
        controlID:'ID-controller',
        statcontrolct:'Controle Statistieken per Controller',
        statcontrolag:'Controle Statistieken per Agent',
        statcontrol:'Controle Statistieken',
        traitement:'Behandeling',
        agentid:'Agent-ID',
        statconvatt:'Statistieken van Gesprekken in de Wacht',

        propesqu:'Voer een smoesje in voor een afspraakverzoek',
        msgacc:'Schrijf alstublieft een pakkende boodschap',
        msgpoke:'Gelieve een Poke-bericht te schrijven',
        heure:'Uur',
        esqv:'Ontwijken',
        esquive:'Ontwijken ontmoetingen en afspraken',
        other:'Anderen',
        sexe:'Geslacht',
        bannir:'Verbannen',
        deconnecter: 'Loskoppelen',
        agntLgn:'Online Agent',
        features:'Kenmerken',
        rdv:'Afspraak',
        holiday:'Vakantie',
        valider:'Valideren',
        panicmsg:'dit paniekbericht?',
        jour:'Dag',
        jours:'Dagen',
        conversation:'Gesprek',
        profil:'Profiel',
        suspendre:'Opschorten',
        expulser:'Verwijderen',
        information:'Informatie',
        meeting:'Vergadering',
        online:'Online',
        total:'Totaal',
        treated:'Behandeld',
        onHold:'In de wacht',
        listPhotoSend:"Lijst van verzonden foto's",
        agentReport:'Gemelde agent',
        suspendu:'Geschorst',
        raison:'Rede',
        etat:'Status',
        actqc4: 'Bericht gevalideerd',
        panicmess:'Paniekbericht',
        contenu:'Inhoud',
        language1:'Taal',
        reportmess:'Meld een bericht',
        enreg:['Ikke registrert ennå?','Registrere'],
        passdiff:'Et annet passord',
        qlogout: 'Wilt u uitloggen?',
        editNote:'Notitie Bewerken',
        annuler:'Annuleren',
        addnote: "Opmerking toegevoegd",
        logout: 'Uitloggen',
        notesuppr: "Notitie verwijderd",
        editer:'Bewerken',
        yes: 'Ja',
        no: 'Nee',
        operatorpage: "Operators-pagina",
        supervisorpage: "Supervisors-pagina",
        adminpage: "Beheerderspagina",

        required: "Dit veld is verplicht",
        invalid: "Ongeldige e-mail",
        registered: "Dit e-mailadres is niet geregistreerd",
        incorrectpass: "Onjuist wachtwoord",
        atleast: "Wachtwoord moet ten minste",
        characters: "karakters",

        convman: "Beheer van gesprekken",
        agman: "Agentenbeheer",
        notesman: "Beheer notities",
        agrep: "Agenten rapporten",
        gftgtfman: "Beheer van geschenken en gifs",
        tmfldaa: "TextModing map toegangsrechten",
        hlp: "Help",
        pltflng: "Platformtaal",
        pr: "Foto verzoeken",
        wlcm: "Welkom",
        wlcmtxt: "TextModing is een animatieplatform dat is ontworpen voor datingsites en dat essentiële functies biedt voor het beheren van conversaties en agenten. Het verbetert de efficiëntie en winstgevendheid voor partners door tools te bieden die interacties stroomlijnen en de betrokkenheid van gebruikers optimaliseren.",

        messageto: "Bericht aan",
        convatt: "Gesprek in de wacht",
        mincharlen: "Ten minste 120 tekens",
        char: "karakter",
        chars: "tekens",
        pj: "Foto bijlage",
        writemessage: "Schrijf een bericht ...",
        picalreadysent: "Foto gezien door de gebruiker",
        gifpj: "GIFS",
        opengif: "GIF openen",
        sendMessage: "Stuur het bericht",
        reportSup: "Stuur een bericht naar de supervisor",
        conv: "Gesprek ID",
        say: "zegt",
        timeIntro: "bij",
        elements: 'elementen',
        element: "element",
        none: "Geen",

        stat: "Statistieken",
        french: 'Frans',
        english: 'Engels',
        italian: 'Italiaans',
        dutch: 'Nederlands',
        spanish: 'Spaans',
        german: 'Duitse',
        botprofil: "Botprofiel",
        userprofil: "gebruikersprofiel",
        otherInfo: "Andere belangrijke informatie",
        codepost: "Postcode",
        ville: "Stad",
        region: "Regio",
        pays: "Land",
        taille: "Grootte",
        eyes: 'Kleur ogen',
        hair: 'Haarkleur',
        morph: 'Morfologie',
        situation: 'Burgerlijke staat',
        searchfor: 'Zoeken naar',
        job: 'Beroep',
        signe: 'Onderscheidende tekens',
        descri: 'Beschrijving',
        infosupp: 'Aanvullende informatie',
        supervischat: 'Toezicht chat',
        statconvop: "Stat en operator gesprek",
        convs: "Gesprekken",
        report: 'Rapport',
        agentInform:'Geïnformeerde agent',
        events: 'Gebeurtenis',
        photosrequests: "Foto's aanvragen",
        warmup: 'Opwarming',
        gifattch: 'Gif-bijlage',
        fakeprofiles: 'Nepprofielen',
        pagenotfound: 'Pagina niet gevonden',

        suivvraiprof: 'Echte profielen bewaking',
        suiviop: "Operators monitoring",
        admintion: "Administratie",
        affiliation: "Lidmaatschap",

        operator: "Operator",
        supervisor: "Supervisor",
        administrator: "Beheerder",
        week: "week",
        weeks: "weken",
        today: 'Vandaag',

        start: 'Start',
        end: 'Einde',
        platform: 'Platformen',
        search: 'Zoeken',
        go: "Go",
        allmasc: "Alle",
        allfem: "Alle",

        id: "Identifier",
        opname: "Operator naam",
        sentmess: "Verzonden berichten",
        recmess: "Ontvangen berichten",
        relmess: "Gestuurde berichten",
        date: "Datum",
        messid: "identificatiecode bericht",
        convid: "Identificatiecode gesprek",
        exped: "Afzender",
        recpt: "Ontvanger",
        opsign: "Operator te melden",
        opexped: "Operatorr verzenden",
        action: "Actie",
        messs: "Berichten",

        filterdate: 'Filter zoekopdrachten tussen twee datums',
        filtermess: 'Filter conversatie op bericht',
        from: 'Van',
        to: 'naar',
        eventList: 'Gebeurtenissenlijst',
        num: 'Aantal',
        eventSearch: 'Zoeken naar een gebeurtenis',
        actions: 'Acties',

        eventname: 'Naam gebeurtenis',
        eventdate: 'Gebeurtenisdatum',
        uploadimage: "Upload afbeeldingen",
        role: "Rol",
        logIn: "Aanmelden",
        ppaccept1: "Door in te loggen op ons platform bevestig je dat je onze",
        ppaccept2: "en de juiste training hebt gevolgd om uw opdracht uit te voeren.",
        pp: "vertrouwelijkheidsovereenkomst",
        copyright: "© 2023-2024 Copyright TextModing® - Alle rechten voorbehouden.",
        accountant: "Accountant",
        qualitycontroller: "Kwaliteitscontroleur",
        adminid: "Admin ID",
        accountantid: "Accountant ID",
        operatorid: "Operator ID",
        qcid: "Quality Controller ID",
        supervisorid: "Supervisor ID",
        convonhold: "Gesprekken in de wacht",
        supdash: "Supervisor dashboard",
        choverv: "Chatoverzicht",
        months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
        year: 'Jaar',
        month: 'Maand',
        startingdate: 'Begindatum',
        closingdate: 'Einddatum',

        agents: "Agenten",
        out: "Uitgangen",
        in: "Inputs",
        details: "Details",
        panicroom: "Paniekkamer",
        notes: "Notities",
        teasermessages: "Teaser messages",
        pokemessages: "Poke-berichten",
        attdisc:'Wachten op Discussie',
        gifajt:'Gif Toevoegen',
        stopmessages: "Stopberichten",
        sent: "Verzonden",
        asnwered: "Beantwoord",
        requested: "Aangevraagd",
        logbook: "Logboek",
        recorded: "Opgenomen",
        notrecorded: "Niet opgenomen",
        duplicatedmessages: "Dubbele berichten",
        back: "Terug",
        suty: "Abonnementstype",
        ag: "Leeftijd",
        gend: "Geslacht",
        sexor: "Seksuele geaardheid",
        tow: "Stad",
        
        users: "Gebruikers",
        sender: "Afzender",
        receiver: "Ontvanger",
        agent: "Agent",
        status: "Status",
        read: "Gelezen",
        active: "Actief",
        edit: "Wijzigen",
        user: "Gebruiker",
        sendnote: "Verstuur een notitie",
        oh: "Wachten",
        mess: "Bericht",
        trconv: "Behandelde gesprekken",
        idconv: "Gesprek-ID",
        idmess: "Bericht-ID",
        otherimp: "Andere belangrijke informatie",
        otherimp1: "Vergadering/ Ontmoetingen/ Vakanties",
        otherimp2: "Permanente informatie",
        otherimp3: "Nuttige informatie, niet permanent",
        
        aglist: "Agent lijst",
        pfsperf: "Platformprestaties",
        agperf: "Agent prestaties",
        nwag: "Nieuwe agenten toevoegen",
        agname: "Agent naam",
        agcountry: "Land agent",
        aglogin: "Agent login",
        agloginpw: "Agent wachtwoord",
        langpf: "Taalplatform",
        onl: "Online",
        offl: "Offline",
        suspend: "Opschorten",
        koff: "Opnieuw verzenden",
        grotot: "Totaal bruto",
        agnumbewoon: "Aantal nepprofielen",
        totsenmess: "Totaal verzonden berichten",
        totrecmess: "Totaal ontvangen berichten",
        totrep: "Totaal rapporten",
        real: "Echte naam",
        prov: "Provincie",
        weight: "Weight",
        child: "Kinderen",
        time: "Tijd",
        fstnm: "Voornaam",
        lstnm: "Achternaam",
        dtofbir: "Geboortedatum",
        skypeid: "Skype-ID",
        crnwag: "Nieuwe agent aanmaken",
        usrprfl: "Gebruikersprofiel",
        sndmess: "Bericht verzenden",
        blckusr: "Gebruiker blokkeren",
        lstnts: "Lijst met notities",
        crtsndnts: "Maak / Verzend een notitie",
        evnt: "Gebeurtenis",
        ntid: "Notitie-ID",
        nt: "Opmerking",
        dlt: "Verwijderen",
        cndid: "Kandidaat-ID",
        language: "Taal",
        perc1: 'Je hebt ',
        perc2: " van het bericht van de gebruiker samengesteld",
        giftsatt: "Bijgevoegde geschenken",
        sndnt: "Verzend de notitie",
        lsttsrmsg: "Lijst met teaser-berichten",
        crttsrmsg: "Teaser-berichten maken",
        idtsrmsg: "Teaser bericht ID",
        tsrmsg: "Teaser bericht",
        dlvrdt: "Geleverd aan",
        wiseg:"Wijze Gifs",
        sexg:"Seksuele Gifs",
        gayg:"Gay Gifs",
        Lesbg: "Lesbische Gifs",
         transg:"Transgender Gifs",
        travg:"Travestiet Gifs",
                
        bdsm:"BDSM",
        dont: "Blijf niet te lang",
        blj:"Blowjob",
        org:"Orgasme",
        vagpen:"Vaginale penetratie",
        sod:"Sodomie",
        titss:"Tieten",
        
        bdsmg: "BDSM Gay",
        wisegg: "Wijze homo gifs",
        sexgg:"Sex Gay Gifs",
        
        bdsml: "Lesbische BDSM",
        wiselg:"Wijze Lesbische Gifs",
        sexlg:"Sex Lesbian Gifs",
        
        maletrans:"Mannelijke transgender",
        femaletrans:"Vrouwelijke transgender",
        lstpkmsg: "Lijst met gepokte berichten",
        crtpkmsg: "Maak een poke bericht",
        idpkmsg: "Poke bericht ID",
        pkmsg: "Poke bericht",
        lststpmsg: "Lijst met stopberichten",
        idstpmsg: "Stop bericht ID",
        stpmsg: "Stopbericht",
        sntto: "Verzonden naar",
        agntwrtngstp: "Agent die de stop schrijft",
        dtofdlvr: "Datum van levering",
        dtofans: "Datum van antwoord",
        stpperfanal: "Stop prestatieanalyse",
        stpgrssttl: "Stop brutototaal",
        rsptostp: "Reactie op stopzetting",
        rsptostp2: "Reactie op de Hook",
        rsptostp3: "Reactie op de Poke",
        stpprcntresp: "Stop % respons",
        avrgrsptm: "Gemiddelde responstijd",

        lstphtrq: "Lijst met opgevraagde foto's",
        phttp: "Soort foto",
        idrq: "Verzoek ID",
        pht: "Foto",
        upld: "Uploaden",
        snd: "Verzenden",
        lstrprt: "Lijst met rapporten",
        rpttp: "Rapporttype",
        idrprt: "Rapport-ID",
        msgrprtd: "Gerapporteerd bericht",
        infrmagnt: "Inform agent",
        lstlgbk: "Lijst van logboek",
        lstmssnglgbk: "Lijst van ontbrekende logboeken",
        lgbktp: "Type logboek",
        idlgbk: "Logboek ID",
        infs: "Informatie",
        lvlofimprt: "Niveau van belangrijkheid",
        prmnnt: "Permanent",
        ntprmnnt: "Niet permanent",
        mssnglgbk: "Ontbrekend logboek",
        mrklgbk: "Markeer logboek",

        dh: "Datum en uur",
        mid: "Bericht-ID",
        repmess: "Dit bericht melden",
        bts: "Bot zegt",
        obj: "Object",
        plc: "Plaats",

        lstdplmss: "Lijst met dubbele berichten",
        iddplmss: "ID gedupliceerde berichten",
        lstgfs: "Lijst met GIF's",
        ddgfs: "Nieuwe GIF toevoegen",
        gftp: "GIF type",
        gfimg: "GIF-afbeelding",

        nat: "Aard",
        tmslt: "Tijdslot",
        gfsnt: "Verzonden GIF's",

        qcdash: "Dashboard voor kwaliteitscontrole",
        qctrl: "Kwaliteitscontrole",
        lstmess: "Lijst met berichten",
        qcpnl: "Kwaliteitscontrole pannel",
        ptreq: "Foto aanvragen",
        sp: "Foto verzenden",
        sgft: "Cadeau verzenden",
        sgif: "Gif verzenden",
        qccrit: "QC-criteria",
        qccrit1: "Goede chat",
        qccrit2: "Slechte chat",
        qccrit3: "Gebruikersprofiel bekeken",
        qccrit4: "Botprofiel bekeken",
        qccrit5: "Conversatie aangegaan door de bot",
        qccrit6: "CTA door agent",
        qccrit7: "Gesprek niet zinvol",
        qccrit8: "Gebruiker ontevreden over de bot",
        qccrit9:'Goed genoteerd',
        actqc1: "Informeer de agent",
        actqc2: "De agent opschorten",
        actqc3: "Ontsla de agent",
        adc1:'Uitstekende agent',
        adc2:'Gemiddelde agent',
        adc3:'Slechte agent',
        sndmailnaaradm: "Stuur een e-mail naar de beheerder",
        yrnm: "Uw naam",
        yrcmpnm: "Uw bedrijfsnaam",
        yrmladdr: "Uw e-mailadres",
        yradmid: "Uw admin-ID",
        objctfyrrqst: "Object van uw verzoek",
        yrmss: "Uw bericht",
        yrattchmt: "Bijlage",
        sndthml: "Verzend de e-mail",
        ddnwadm: "Nieuwe admin toevoegen",
        nm: "Naam",
        mladd: "E-mailadres",
        cmpnnm: "Bedrijfsnaam",
        tmfldr: "TekstModing map",
        psswrd: "Wachtwoord",
        accssar: "Toegangsgebieden",
        valdt: "Geldigheid",
        rgstr: "Registreren",

        accdash: "Accountant Dashboard",
        acc: "Rekeningen",
        acc1: "Agent statistieken",
        acc2: "Betaling per overschrijving",
        acc3: "Betaling via Paypal",
        acc4: "Betaling per wijze",
        acc5: "Betaling via Yoursafe",
        acc6: "Boekhouder toevoegen",

        admdash: "Admin dashboard",
        chttlov: "Chattool overzicht",
        suptraffman: "Toezicht op en beheer van het verkeer",
        vw: "Zien",
        
        ratepermess: "Tarief per bericht",
        bonus: "Bonus",
        totpay: "Totale betaling",
        paymeth: "Betalingsmethode",
        grosstot: "Bruto totaal per maand van ",
        prstat: "Afschrift afdrukken",
        agfn: "Voornaam agent",
        agln: "Achternaam agent",
        payadd: "Paypal e-mailadres",
        nysent: "Nog niet verzonden",
        wiseadd: "E-mailadres van Wise",
        yoursafeadd: "E-mailadres van Yoursafe",
        bankn: "Banknaam",
        iban: "IBAN",
        bic: "BIC code",

        lstsup: "Lijst van toezichthouders",
        ddnwsup: "Nieuwe supervisor toevoegen",
        trffcmng: "Verkeersbeheer",
        supnm: "Naam supervisor",
        suplgn: "Supervisor login",
        suplgnpsswrd: "Wachtwoord voor aanmelding supervisor",
        idcnddt: "Kandidaat ID",
        cntry: "Land",
        lgn: "Login",
        crtnwsup: "Nieuwe supervisor maken",
        fragntonln: "Free agent online",
        msgalltag: "Bericht toewijzing aan agent",
        allct: "Toewijzen",
        addqc: "Kwaliteitscontroleur toevoegen",
        crtqc: "Creëer kwaliteitscontroleur",
        crtnwacc: "Nieuwe accountant aanmaken",

        gifn: "Te verzenden GIFs",
        restfn: "Reset",
        gifsel: "Leeg (selecteer gifs)",
        alerttxt: "GIFs succesvol verzonden.",
        option: "opties",

        phalert: "Verzoek om foto verzonden.",
        phtitre: "Verzoek om foto",
        phlabel: "Soort foto",
        phsend: "Verzenden",
        phload: "Laden...",

        delalert: "Verwijderd",
        deltitre1: "Wil je deze aanvraag echt ",
        deltitre2: "verwijderen ?",
        delauteur: "Auteur",
        deldesc: "Beschrijving",
        delaccept: "Ja",
        delrefuse: "Nee",
        delload: "Laden..",

        lstqc: "Lijst van kwaliteitscontroleurs",
        qcnm: "Naam kwaliteitscontroleur",
        qclgn: "Login kwaliteitscontroleur",
        qclgnpsswrd: "Inlogwachtwoord kwaliteitscontroleur",
        lstacc: "Lijst van boekhouders",
        accnm: "Naam boekhouder",
        acclgn: "Login boekhouder ",
        acclgnpsswrd: "Inlogwachtwoord boekhouder" ,

        biev: "Welkom",
        selecrt: "Berichtverzendingsinterface",
        chxha: "Kies een echt en een vals profiel",
        chxF: "Lijst van echte profielen",
        chxV: "Lijst van valse profielen",
        nomd: "Naam",
        langs: "Taal",
        errdj: "Selecteer een echt en een vals profiel",
        plch: "Voer je bericht in",
        pokePh: "Poke-berichten (nog niet verzonden)",
        teaserPh: "Lijst van aanhaakberichten (nog niet verzonden)",
        inter: "Testinterface van TextModing",
        interSel: "Selecteer alstublieft de taken die u wilt uitvoeren",
        des1: "Het verzenden van een gepersonaliseerd bericht naar een profiel.",
        des2: "Het verzenden van een bericht om de aandacht van het profiel te trekken.",
        des3: "Gepersonaliseerde verzending van boeiende berichten naar nieuwe inschrijvingen om gesprekken te starten.",
        des4: "Het verzenden van een bericht om het gesprek te herstarten.",
        tit1: "Bericht verzenden",
        tit2: "Teaser bericht",
        tit3: "Poke bericht",
        tit4: "Stop bericht",
        tache: "Taak",
        bienvTest: "Welkom bij de testinterface van",
        enterEP: "Voer uw e-mail en wachtwoord in",
        instTest: "Instructies",
        sendInt: "Verzenden Interface",
        unPlP: "Kies één of meer echte profielen.",
        unNouv: "Nieuwe",
        unReç: "Ontvangen",
        unNouv1: "Nieuw",
        payTot: "Totale betaling",
        allou: "Al toegewezen",
        Nallou: "Nog niet toegewezen",
        totSoum: "Totaal bericht",
        Soum: "Ingediend voor QC",
        nonSoum: "Niet ingediend voor QC",
        boot: "Bot",
        rvr: "Afspraken/Bijeenkomsten/Vakanties",
        banit: "Verbannen",
        suspt: "Geschorst",
        aucunt: "Geen bericht",
        msgstop: "Schrijf een vervolgbericht",
        messrt: "Berichttarief",
        pkmessrt: "Poke berichttarief",
        tsrmessrt: "Teaser berichttarief",
        stpmessrt: "Stop berichttarief",
        bnsrt: "Bonustarief",
        mesdes: "Berichten sturen op een datingsite is cruciaal om de aandacht van de ander te trekken en hun interesse te wekken. Het is belangrijk om authentiek te blijven, oprechte interesse te tonen en open vragen te stellen die een reactie aanmoedigen.",
        mesdes1: "Kies uit de profielen een echt en een nep profiel om een bericht te sturen.",
        mesdes2: "Zorg ervoor dat u de juiste correspondentie taal kiest.",
        mesdes3: "Schrijf uw testbericht.",
        mesdes4: "U heeft 5 minuten om te reageren op het bericht op het animatieplatform voor operators.",
        mesdes5: "Controleer uw statistieken in uw beheerdersdashboard.",
        stopdes: "Follow-up berichten op een datingsite zijn bedoeld om de interesse weer op te wekken en het gesprek opnieuw te starten na een periode van stilte.",
        stopdes1: "Kies een echt en een nep profiel",
        stopdes2: "Stel een gebruikelijk bericht op",
        stopdes3: "Wacht 24 uur nadat de chatoperator op het bericht van het echte profiel heeft gereageerd",
        stopdes4: "Na 24 uur verandert het niet beantwoorde bericht van het echte profiel in een Follow-up Bericht, dat de operator zal opstellen en verzenden.",
        stopdes5: "Controleer uw statistieken in uw beheerdersdashboard",
        teaserdes: "Teaserberichten op een datingsite zijn cruciaal om de aandacht te trekken en de interesse van de ander te wekken. Het is belangrijk om authentiek te blijven, oprechte interesse te tonen en open vragen te stellen die een reactie aanmoedigen",
        teaserdes1: "Kies uit de profielen een echt en een nep profiel om een teaserbericht te sturen",
        teaserdes2: "Zorg ervoor dat u de juiste correspondentie taal kiest",
        teaserdes3: "Stel op het animatieplatform voor operators de teaserberichten op",
        teaserdes5: "Controleer uw statistieken in uw beheerdersdashboard",
        pokedes: "Poke-berichten voor een datingsite zijn korte en lichte berichten die zijn ontworpen om subtiel en boeiend de aandacht te trekken.",
        pokedes1: "Kies het land en de taal van de echte profielen waarmee je Poke-berichten wilt versturen",
        pokedes2: "Selecteer een of meerdere profielen waarmee je Poke-berichten wilt versturen",
        pokedes3: "Controleer je statistieken in je beheerdersdashboard",
        stopbla: "Lijst van al verzonden opvolgingsberichten",
        respVp: "Antwoord van het echte profiel",
        pasEn: "nog niet.",
        notifAccr: "Test Aanknopingsbericht: Profielkeuze succesvol gemaakt",
        notifPoke: "Test Poke Bericht: Profielkeuze succesvol gemaakt",
        pokeErr: "Klik op een of meer echte profielen",
        teaserErr: "klik op een nep en een echt profiel",
        reqGift : "Cadeaus aanvragen",
        reqGift1 : "Soort cadeau",
        reqGift2 : "Cadeau aanvraag",
    },

    { // es

        usrsd : "Lado del usuario",
        btsd : "Lado del bot",
        nttkn : "Notas tomadas",
        mssdnt : "Notas perdidas",
        gfts : "Regalos",
        rqsts : "Solicitudes",
        messonhldallctd : "Mensaje en espera ya asignado",
        messonhldntallctd : "Mensaje en espera no asignado",
        totnummess : "Número total de mensajes",
        messpssdqc : "Mensajes aprobados en control de calidad",
        messntpssqc : "Mensajes no aprobados en control de calidad",
        paymnts : "Pagos",
        bnk : "Banco",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Recibido",
        envoyees: "Enviado",
        dmndees: "Solicitado",
        nvaux: "Nuevo",

        adddest: "Dirección de correo electrónico del destinatario",

        cmpn: "Empresa",
        tmfld: "Carpeta Textmoding",
        accar: "Área de acceso",
        assvldt: "Validez de acceso",

        acc8: "Configuración de tarifas y bonos",

        mtng: "Reunión",
        rp: "Perfil real",
        fp: "Perfil falso",
        infopernoper: "Información permanente / no permanente",
        dltnt: "Borrar nota",
        dltmesspnc: "¿Realmente quieres borrar este mensaje de pánico?",
        convbnnd: "Conversación prohibida",
        suspconv: "Suspender la conversación",
        upldd: "Subido",
        addpht: "Añadir foto",
        phtsnttofp: "Lista de fotos enviadas a perfiles falsos",
        pkmesscrtd: "Mensaje Poke creado con éxito",
        updt: "Actualización",
        dltpkmess: "¿Realmente quieres borrar este mensaje poke?",
        plt: "Plataforma",
        dltdmd: "¿Realmente quieres borrar esta petición? ",
        agntinfrmd: "Agente informado",
        dlttsrmess: "¿Realmente desea borrar este mensaje de provocación?",
        updttsrmess: "Actualizar mensaje teaser",

        updtdsucc: "Actualizado con éxito",
        nonotif: "Sin notificación",
        continfo: "Información sobre el contenido",
        exp: "Remitente",
        recep: "Receptor",
        mdpupdtd: "Contraseña actualizada con éxito",
        opsusp: "Operador suspendido con éxito",
        opdec: "Operador desconectado con éxito",
        decop: "¿Realmente desea desconectar a este operador:  ",
        frop: "¿Realmente desea despedir a este operador:  ",
        chngmdp: "Cambiar contraseña",
        suspop: "¿Realmente quiere suspender a este operador: ",
        onhld: "En espera",
        supprmess: "Borrar este mensaje",
        messinfo: "Información del mensaje",
        vld: "Confirmar",
        ok: "Ok",
        enc: "En curso ...",

        updtmdp: "¿Desea cambiar la contraseña?",
        nwmdp: "Introduzca una nueva contraseña",
        dltsup: "¿Realmente desea borrar este supervisor?",
        ntsup: "Nota a un supervisor",
        nthr: "Nota aquí...",
        dltimg: "¿De verdad quieres borrar esta foto?",
        phttsnd: "Foto para enviar",
        vd: "Vacío (seleccione una foto)",
        addd: "Añadida",
        nwrdv: "Añadir nueva información",
        cnfrm: "Confirmar",
        dltrdv: "¿Realmente desea suprimir este Rendez-vous/ Reuniones/ Vacaciones?",
        addnonperminfo: "Añadir información no permanente",
        dltinfo: "¿Realmente desea eliminar esta información?",
        idd: "ID",
        addperminfo: "Añadir información permanente ",
        messreport: "Mensaje informado al supervisor",
        usrnm: "Nombre de usuario",

        pp11 : "Creativ'Network, una empresa de intermediación profesional para trabajos web, con sede en 71-75 Shelton Street, Covent Garden, Londres, WC2H 9JQ, y con oficina de representación en Roodborstjesstraat 4, Wondelgem 9032, en adelante denominada « la Sociedad »,",
        pp12 : "Y:",
        pp13 : "Trabajador autónomo, que, en virtud del contrato de Trabajador Autónomo que lo vincula con « La Sociedad » utiliza los materiales así como las propiedades intelectuales de esta última, en adelante denominado « el Trabajador Autónomo »,",
        pp14 : "En adelante denominados individualmente « la Parte » y colectivamente « las Partes »,",
        pp21 : "La Sociedad opera una plataforma de animación de sitios de citas de la que es la propietaria exclusiva. La gestión operativa de dicha plataforma está a cargo de operadores de chat, supervisores, líderes de equipo, controladores de calidad, empleados como trabajadores autónomos por la Sociedad.",
        pp22 : "En el marco de esta relación profesional, el Trabajador Autónomo tendrá acceso a información confidencial relacionada con la plataforma, los usuarios de los sitios de citas, los procesos operativos, así como cualquier otra información protegida que pertenezca a la Sociedad.",
        pp23 : "Dicho esto, se acuerda lo siguiente:",
        pp31 : "El presente acuerdo de confidencialidad tiene como objetivo proteger la información confidencial que la Sociedad pueda comunicar al Trabajador Autónomo en el marco de sus funciones relacionadas con la gestión de la plataforma de animación de sitios de citas.",
        pp41 : "A efectos del presente acuerdo, las « Información Confidencial » se refiere a toda la información, en cualquier forma (escrita, oral, electrónica, etc.), relacionada con la plataforma de animación de sitios de citas, los usuarios, las estrategias de desarrollo, los procesos técnicos y operativos, los datos financieros, así como cualquier otra información designada como confidencial por la Sociedad, que el Trabajador Autónomo pueda recibir o tener acceso en el marco de su trabajo.",
        pp51 : "El Trabajador Autónomo se compromete a:",
        pp52 : "- No divulgar, reproducir, transmitir o hacer accesible la Información Confidencial, en su totalidad o en parte, a terceros, sin la autorización escrita previa de la Sociedad.",
        pp53 : "- Utilizar la Información Confidencial únicamente en el contexto de las actividades relacionadas con la gestión de la plataforma y exclusivamente en beneficio de la Sociedad.",
        pp54 : "- Tomar todas las medidas necesarias para proteger la confidencialidad de la Información Confidencial y evitar cualquier uso no autorizado.",
        pp55 : "- Devolver a la Sociedad, a solicitud, cualquier documento o material que contenga Información Confidencial, sin conservar copias de ningún tipo.",
        pp61 : "El presente acuerdo entra en vigor en el momento en que el trabajador acepte durante su identificación para acceder a la plataforma de animación y permanecerá en vigor durante toda la duración de la relación profesional entre la Sociedad y el Trabajador Autónomo. Las obligaciones de confidencialidad continuarán durante un período de 10 años después de la finalización de dicha relación, cualquiera que sea la causa.",
        pp71 : "No se consideran Información Confidencial:",
        pp72 : "- La información que ya era conocida por el público en el momento de su comunicación al Trabajador Autónomo, o que se haga pública de otra manera que no sea por violación de este acuerdo.",
        pp73 : "- La información que el Trabajador Autónomo pueda demostrar que ha recibido legítimamente de un tercero no vinculado por una obligación de confidencialidad.",
        pp74 : "- La información desarrollada de manera independiente por el Trabajador Autónomo sin utilizar la Información Confidencial.",
        pp81 : "En caso de incumplimiento de las obligaciones previstas en el presente acuerdo, el Trabajador Autónomo reconoce que la Sociedad podrá emprender todas las acciones legales necesarias para proteger sus derechos y obtener compensación por los daños sufridos.",
        pp91 : "El presente acuerdo se rige por la legislación belga. En caso de disputa relativa a la interpretación o ejecución del presente acuerdo, las Partes se comprometen a buscar una solución amistosa. En caso de falta de acuerdo amistoso, los tribunales competentes de Gante serán los únicos competentes.",
        pp1 : "Entre las Partes",
        pp2 : "Preámbulo",
        pp3 : "Artículo 1: Objeto",
        pp4 : "Artículo 2: Definición de Información Confidencial",
        pp5 : "Artículo 3: Obligaciones del Trabajador Autónomo",
        pp6 : "Artículo 4: Duración",
        pp7 : "Artículo 5: Exclusiones",
        pp8 : "Artículo 6: Disposiciones Generales",
        pp9 : "Artículo 7: Ley Aplicable y Jurisdicción Competente",

        paymail: 'Dirección de correo electrónico de pago',
        scc: 'Creado',
        italian: 'Italiano',
        mail:'Correo electrónico',
        dutch: 'Holandés',
        spanish: 'Español',
        german: 'Alemán',
        convonhold: "Conversaciones en espera",
        supdash: "Cuadro de mando del supervisor",
        choverv: "Visión general del chat",
        login: 'Inicio de sesión',
        password: 'Contraseña',

        qlogout: '¿Quieres cerrar sesión?',
        editNote:'Editar Nota',
        annuler:'Cancelar',
        addnote: 'Nota añadida',
        logout: 'Cerrar sesión',
        editer:'Editar',
        yes: 'Sí',
        notesuppr:'Nota borrada',
        no: 'No',
        operatorpage: "Página de operadores",
        supervisorpage: "Página de los supervisores",
        adminpage: "Página de administradores",

        required: "Este campo es obligatorio",
        invalid: "Correo electrónico no válido",
        registered: "Esta dirección de correo electrónico no está registrada",
        incorrectpass: "Contraseña incorrecta",
        atleast: "La contraseña debe contener al menos",
        characters: "caracteres",

        convman: "Gestión de conversaciones",
        agman: "Gestión de agentes",
        notesman: "Gestión de notas",
        agrep: "Informes de agentes",
        gftgtfman: "Gestión de regalos y gifs",
        tmfldaa: "Permiso de acceso a la carpeta TextModing",
        hlp: "Ayuda",
        pltflng: "Lenguaje de plataforma",
        pr: "Solicitudes de fotos",
        wlcm: "Bienvenido",
        wlcmtxt: "TextModing es una plataforma de animación diseñada para sitios de citas, que ofrece funciones esenciales para la gestión de conversaciones y agentes. Mejora la eficiencia y la rentabilidad de los socios proporcionando herramientas que agilizan las interacciones y optimizan el compromiso de los usuarios.",

        messageto: 'Mensaje para',
        convatt: 'Conversación en espera',
        mincharlen: 'Al menos 120 caracteres',
        char: 'carácter',
        chars: 'caracteres',
        pj: 'Foto adjunta',
        writemessage: 'Escribe un mensaje ...',
        picalreadysent: 'Foto vista por el usuario',
        gifpj: 'GIFS',
        opengif: 'Abrir GIF',
        sendMessage: 'Enviar el mensaje',
        reportSup: 'Enviar una nota al Supervisor',
        conv: 'ID de conversación',
        say: 'Dice',
        timeIntro: 'en',
        elements: 'elementos',
        element: 'elemento',
        none: 'Ninguno',

        stat: 'Estadísticas',
        french: 'Francés',
        english: 'Inglés',
        botprofil: 'Perfil del Bot',
        userprofil: "Perfil de usuario",
        otherInfo: 'Otras informaciones importantes',
        codepost: 'Código postal',
        ville: 'Ciudad',
        region: 'Región',
        pays: 'País',
        taille: 'Talla',
        eyes: 'Color de ojos',
        hair: 'Color de pelo',
        morph: 'Morfología',
        situation: 'Estado civil',
        searchfor: 'Buscar',
        job: 'Ocupación',
        signe: 'Signos distintivos',
        descri: 'Descripción',
        infosupp: 'Información adicional',

        supervisiontchat: 'Supervisión del chat',
        statconvop: 'Conversación de estadísticas y operadores',
        convs: 'Conversaciones',
        report: 'Informe',
        agentInform:'Agente informado',
        events: 'Evento',
        photosrequests: 'Solicitud de fotos',
        warmup: 'Calentamiento',
        gifattch: 'Gif adjunto',
        fakeprofiles: 'Perfiles falsos',
        pagenotfound: 'Página no encontrada',

        suivvraiprof: 'Supervisión de perfiles reales',
        suiviop: 'Monitorización de operadores',
        admintion: 'Administración',
        affiliation : 'Afiliación',

        operator: 'Operador',
        supervisor: 'Supervisor',
        administrator: "Administrador",

        week: 'semana',
        weeks: 'semanas',
        today: 'Hoy',

        start: 'Inicio',
        end: 'Fin',
        platform: 'Plataformas',
        search: 'Búsqueda',
        go: 'Ir',
        allmasc: 'Todos',
        allfem: 'Todos',

        id: 'Identificador',
        opname: 'Nombre del operador',
        sentmess: 'Mensajes enviados',
        recmess: 'Mensajes recibidos',
        relmess: 'Mensajes reclamados',

        date: 'Fecha',
        messid: 'Identificador del mensaje',
        convid: 'Identificador de conversación',
        exped: 'Remitente',
        recpt: 'Receptor',
        opsign: 'Operador a informar',
        opexped: "Operador que envía",
        action: 'Acción',
        messs: 'Mensajes',
        filterdate: 'Filtrar búsquedas entre dos fechas',
        filtermess: 'Filtrar conversación por mensaje',
        from: 'De',
        to: 'to',
        eventList: 'Lista de eventos',
        num: 'Número',
        eventSearch: 'Buscar un evento',
        actions: 'Acciones',

        eventname: 'Nombre del evento',
        eventdate: 'Fecha del evento',
        uploadimage: "Subir imágenes",
        role: "Rol",
        logIn: "Iniciar sesión",
        ppaccept1: "Al iniciar sesión en nuestra plataforma, confirmas que has leído nuestra",
        ppaccept2: "y ha recibido la formación adecuada para llevar a cabo su tarea",
        pp: "acuerdo de confidencialidad",
        copyright: "© 2023-2024 Copyright TextModing® - Todos los derechos reservados",
        accountant: "Contable",
        qualitycontroller: "Controlador de calidad",

        adminid: "ID de administrador",
        accountantid: "ID del contable",
        operatorid: "ID de operador",
        qcid: "ID de controlador de calidad",
        supervisorid: "ID de supervisor", 
        confirmpass:'Confirma la contraseña',
        alreadyreg:'¿Ya está registrado? conectarse',
        notifs: "Notificaciones",
        limite:'Limitado',
        save:'Guardar',
        archives:'Archivos',
        ttlmsg:'Total de Mensajes',
        contorlLog:'Login del Controlador',
        nbrmess:'Número de mensajes',
        appQC:'Apreciaciones del QC',
        esqmq:'Esquiva faltante',
        dbspace:'Doble espacio',
        notelu:'Nota leída',
        actpris:'Acción tomada',
        ferme:'Cerrado',
        losn:'Lista de notas enviadas',
        lorn:'Lista de notas recibidas',
        validity:'Validez',
        editInfo:'Modificar una información',
        controlID:'Controlador de ID',
        statcontrolct:'Estadísticas de control por controlador',
        statcontrolag:'Estadísticas de control por Agente',
        statcontrol:'Estadísticas de control',
        traitement:'Tratamiento',
        agentid:'ID de agente',
        statconvatt:'Estadísticas de Conversaciones en Espera',
        propesqu:'Ingrese una excusa para una solicitud de cita',
        msgacc:'Por favor, redacte un mensaje atractivo',
        msgpoke:'Por favor, escribe un mensaje Poke',
        heure:'Hora',
        esqv:'Esquivar',
        esquive:'Evasiones encuentros y citas',
        other:'Otros',
        sexe:'Sexo',
        bannir:'Desterrar',
        deconnecter:'Desconectar',
        agntLgn:'Agente en línea',
        features:'Características',
        rdv:'Cita',
        holiday:'Vacaciones',
        valider:'Validar',
        panicmsg:'¿este mensaje de pánico?',
        jour:'Día',
        jours:'Días',
        conversastion:'Conversación',
        profil:'Perfil',
        suspendre:'Suspender',
        expulser:'Expulsar',
        information:'Información',
        meeting:'Reunión',
        online:'En línea',
        treated:'Tratado',
        onHold:'En espera',
        listPhotoSend:'Lista de fotos enviadas',
        agentReport:'Agente reportado',
        suspendu:'Suspendido',
        raison:'Razón',
        etat:'Estado',
        actqc4:'Mensaje validado',
        panicmess:'Mensaje de pánico',
        contenu:'Contenido',
        reportmess:'Reportar un mensaje',
        language1:'Lengua',
        reg:'Inscribirse',
        enreg:['Todavía no estas registrado?','Registro'],
        passdiff:'Contraseña diferente',
        months: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        year: 'Año',
        month: 'Mes',
        startingdate: 'Fecha de inicio',
        closingdate: 'Fecha de cierre',

        agents: "Agentes",
        out: "Salidas",
        in: "Entradas",
        details: "Detalles",
        panicroom: "Sala de pánico",
        total: "Total",
        notes: "Notas",
        teasermessages: "Mensajes teaser",
        pokemessages: "Mensajes Poke",
        attdisc:'Esperando Discusión',
        gifajt:'Gif Añadir',
        stopmessages: "Mensajes de parada",
        sent: "Enviado",
        asnwered: "Contestado",
        requested: "Solicitado",
        logbook: "Bitácora",
        recorded: "Grabado",
        notrecorded: "No registrado",
        duplicatedmessages: "Mensajes duplicados",
        back: "Volver",
        suty: "Tipo de suscripción",
        ag: "Edad",
        gend: "Sexo",
        sexor: "Orientación sexual",
        tow: "Ciudad",
        
        users: "Usuarios",
        sender: "Remitente",
        receiver: "Receptor",
        agent: "Agente",
        status: "Estado",
        read: "Lectura",
        active: "Activo",
        edit: "Modificar",
        user: "Usuario",
        sendnote: "Enviar una nota",
        oh: "Esperando",
        mess: "Mensaje",
        trconv: "Conversaciones tratadas",
        idconv: "ID de conversación",
        idmess: "ID de mensaje",
        otherimp: "Otras informaciones importantes",
        otherimp1: "Reunión/ Encuentros/ Vacaciones",
        otherimp2: "Informaciones permanentes",
        otherimp3: "Informaciones útiles, no permanentes",
        
        aglist: "Lista de agentes",
        pfsperf: "Rendimiento de la plataforma",
        agperf: "Rendimiento de los agentes",
        nwag: "Añadir nuevos agentes",
        agname: "Nombre del agente",
        agcountry: "País del agente",
        aglogin: "Inicio de sesión del agente",
        agloginpw: "Contraseña del agente",
        langpf: "Plataforma lingüística",
        onl: "En línea",
        offl: "Fuera de línea",
        suspend: "Suspender",
        koff: "Reenviar",
        grotot: "Total bruto",
        agnumbewoon: "Número de perfiles falsos",
        totsenmess: "Total de mensajes enviados",
        totrecmess: "Total de mensajes recibidos",
        totrep: "Total de informes",
        real: "Nombre real",
        prov: "Provincia",
        weight: "Peso",
        child: "Niños",
        time: "Hora",
        fstnm: "Nombre",
        lstnm: "Apellido",
        dtofbir: "Fecha de nacimiento",
        skypeid: "ID de Skype",
        crnwag: "Crear nuevo agente",
        usrprfl: "Perfil de usuario",
        sndmess: "Enviar mensaje",
        blckusr: "Bloquear usuario",
        lstnts: "Lista de notas",
        crtsndnts: "Crear / Enviar una nota",
        evnt: "Evento",
        ntid: "ID de nota",
        nt: "Nota",
        dlt: "Borrar",
        cndid: "ID del candidato",
        language: "Idioma",
        perc1: "Ha compuesto el ",
        perc2: " del mensaje del usuario",
        giftsatt: "Regalos adjuntos",
        sndnt: "Enviar la nota",
        lsttsrmsg: "Lista de mensajes teaser",
        crttsrmsg: "Crear mensajes teaser",
        idtsrmsg: "ID del mensaje teaser",
        tsrmsg: "Teaser message",
        dlvrdt: "Entregado a",
        wiseg:"Gifs sabios",
        sexg:"Gifs Sexuales",
        gayg:"Gifs Gays",
        Lesbg: "Lesbian Gifs",
         transg:"Transgender Gifs",
        travg:"Travesti Gifs",
                
        bdsm:"BDSM",
        dont: "No tardes",
        blj:"Mamada",
        org:"Orgasmo",
        vagpen:"Penetración vaginal",
        sod:"Sodomía",
        titss:"Tetas",
        
        bdsmg: "BDSM Gay",
        wisegg: "Wise Gay Gifs",
        sexgg:"Sexo Gay Gifs",
        
        bdsml: "BDSM Lesbiana",
        wiselg:"Wise Lesbian Gifs",
        sexlg:"Sex Lesbian Gifs",
        
        maletrans:"Male transgender",
        femaletrans:"Female transgender",
        lstpkmsg: "Lista de mensajes poke",
        crtpkmsg: "Crear mensaje poke",
        idpkmsg: "ID mensaje poke",
        pkmsg: "Mensaje poke",
        lststpmsg: "Lista de mensajes de parada",
        idstpmsg: "ID de mensaje de parada",
        stpmsg: "Mensaje de parada",
        sntto: "Enviado a",
        agntwrtngstp: "Agente que escribe la parada",
        dtofdlvr: "Fecha de entrega",
        dtofans: "Fecha de respuesta",
        stpperfanal: "Detener análisis de rendimiento",
        stpgrssttl: "Parar total bruto",
        rsptostp: "Respuesta a la parada",
        rsptostp2: "Respuesta al Gancho",
        rsptostp3: "Respuesta al Poke",
        stpprcntresp: "% de respuesta a la parada",
        avrgrsptm : "Tiempo medio de respuesta",

        lstphtrq: "Solicitud de lista de fotos",
        phttp: "Tipo de foto",
        idrq: "ID de la solicitud",
        pht: "Foto",
        upld: "Subir",
        snd: "Enviar",
        lstrprt: "Lista de informes",
        rpttp: "Tipo de informe",
        idrprt: "ID del informe",
        msgrprtd: "Mensaje informado",
        infrmagnt: "Informar al agente",
        lstlgbk: "Lista del libro de registro",
        lstmssnglgbk: "Lista del libro de registro que falta",
        lgbktp: "Tipo de libro de registro",
        idlgbk: "ID del libro de registro",
        infs: "Información",
        lvlofimprt: "Nivel de importancia",
        prmnnt: "Permanente",
        ntprmnnt: "No permanente",
        mssnglgbk: "Falta el libro de registro",
        mrklgbk: "Marcar libro de registro",

        dh: "Fecha y hora",
        mid: "ID del mensaje",
        repmess: "Informar de este mensaje",
        bts: "Bot dice",
        obj: "Objeto",
        plc: "Lugar",

        lstdplmss: "Lista de mensajes duplicados",
        iddplmss: "ID de mensajes duplicados",
        lstgfs: "Lista de GIFs",
        ddgfs: "Añadir nuevo GIF",
        gftp: "Tipo de GIF",
        gfimg: "Imagen GIF",

        nat: "Naturaleza",
        tmslt: "Franja horaria",
        gfsnt: "GIFs enviados",

        qcdash: "Cuadro de mando del controlador de calidad",
        qctrl: "Control de calidad",
        lstmess: "Lista de mensajes",
        qcpnl: "Panel de control de calidad",
        ptreq: "Solicitar foto",
        sp: "Enviar foto",
        sgft: "Enviar regalo",
        sgif: "Enviar Gif",
        qccrit: "Criterios de control de calidad",
        qccrit1: "Buen Chat",
        qccrit2: "Chat malo",
        qccrit3: "Perfil de usuario considerado",
        qccrit4: "Perfil de bot considerado",
        qccrit5: "Conversación iniciada por el bot",
        qccrit6: "CTA por el agente",
        qccrit7: "Conversación sin sentido",
        qccrit8: "Usuario descontento con el bot",
        qccrit9:'Nota bien tomada',
        actqc1: "Informar al agente",
        actqc2: "Suspender al agente",
        actqc3: "Despedir al agente",
        adc1:'Agente excelente',
        adc2:'Agente medio',
        adc3:'Agente mediocre',
        sndmailtoadm: "Enviar un correo electrónico al administrador",
        yrnm: "Tu nombre",
        yrcmpnm: "Tu nombre de empresa",
        yrmladdr: "Su dirección de correo electrónico",
        yradmid: "Su ID de administrador",
        objctfyrrqst: "Objeto de su solicitud",
        yrmss: "Su mensaje",
        yrattchmt: "Archivo adjunto",
        sndthml: "Enviar el correo electrónico",
        ddnwadm: "Añadir nuevo admin",
        nm: "Nombre",
        mladd: "Dirección de correo electrónico",
        cmpnnm: "Nombre de la empresa",
        tmfldr: "Carpeta TextModing",
        psswrd: "Contraseña",
        accssar: "Áreas de acceso",
        valdt: "Validez",
        rgstr: "Registro",

        accdash: "Cuadro de mando contable",
        acc: "Cuentas",
        acc1: "Estadísticas del agente",
        acc2: "Pago por transferencia bancaria",
        acc3: "Pago por Paypal",
        acc4: "Pago por wise",
        acc5: "Pago por Yoursafe",
        acc6: "Añadir un contable",

        admdash: "Admin dashboard",
        chttlov: "Visión general de Chattool",
        suptraffman: "Supervisión y gestión del tráfico",
        vw: "Ver",
        
        ratepermess: "Tarifa por mensaje",
        bonus: "Bonificación",
        totpay: "Pago total",
        paymeth: "Forma de pago",
        grosstot: "Total bruto a mes de ",
        prstat: "Imprimir el extracto",
        agfn: "Nombre del agente",
        agln: "Apellido del agente",
        payadd: "Dirección de correo electrónico de Paypal",
        nysent: "Aún no enviado",
        wiseadd: "Dirección de correo electrónico de Wise",
        yoursafeadd: "Dirección de correo electrónico de Yoursafe",
        bankn: "Nombre del banco",
        iban: "IBAN",
        bic: "Código BIC",

        lstsup: "Lista de supervisores",
        ddnwsup: "Añadir un nuevo supervisor",
        trffcmng: "Gestión de tráfico",
        supnm: "Nombre del supervisor",
        suplgn: "Conexión del supervisor",
        suplgnpsswrd: "Contraseña de conexión del supervisor",
        idcnddt: "ID del candidato",
        cntry: "País",
        lgn: "Conexión",
        crtnwsup: "Crear un nuevo supervisor",
        fragntonln: "Agente libre en línea",
        msgalltag: "Mensaje asignado al agente",
        allct: "Asignar",
        addqc: "Añadir controlador de calidad",
        crtqc: "Crear controlador de calidad",
        crtnwacc: "Crear un nuevo contable",

        gifn: "GIFs para enviar",
        restfn: "Restablecer",
        gifsel: "Vacío (seleccionar GIFs)",
        alerttxt: "GIFs enviados con éxito.",
        option: "opciones",

        phalert: "Solicitud de foto enviada.",
        phtitre: "Solicitud de foto",
        phlabel: "Tipo de foto",
        phsend: "Enviar",
        phload: "Cargando...",

        delalert: "Suprimido",
        deltitre1: "¿Realmente quieres eliminar ",
        deltitre2: "esta solicitud ?",
        delauteur: "Autor",
        deldesc: "Descripción",
        delaccept: "Sí",
        delrefuse: "No",
        delload: "Cargar..",

        lstqc: "Lista de controladores de calidad",
        qcnm: "Nombre del controlador de calidad",
        qclgn: "Inicio de sesión del controlador de calidad",
        qclgnpsswrd: "Contraseña del controlador de calidad",
        lstacc: "Lista de contables",
        accnm: "Nombre del contable",
        acclgn: "Nombre de usuario del contable",
        acclgnpsswrd: "Contraseña del contable",

        biev: "Bienvenido / Bienvenida",
        selecrt: "Interfaz de envío de mensajes",
        chxha: "Elige un perfil verdadero y uno falso",
        chxF: "Lista de perfiles reales",
        chxV: "Lista de perfiles falsos",
        nomd: "Nombre",
        langs: "Lengua",
        errdj: "Selecciona un perfil verdadero y uno falso",
        plch: "Introduce tu mensaje",
        pokePh: "Mensajes Poke (aún no enviados)",
        teaserPh: "Lista de mensajes de enganche (aún no enviados)",
        inter: "Interfaz de prueba de TextModing",
        interSel: "Por favor, seleccione las tareas que desea realizar",
        des1: "Envío de un mensaje personalizado a un perfil.",
        des2: "Envío de un mensaje para captar la atención del perfil.",
        des3: "Envío personalizado de mensajes atractivos a nuevos inscritos para iniciar conversaciones.",
        des4: "Envío de un mensaje para reiniciar la conversación.",
        tit1: "Enviar mensaje",
        tit2: "Mensaje de gancho",
        tit3: "Mensaje poke",
        tit4: "Mensaje de parada",
        tache: "Tarea",
        bienvTest: "Bienvenido a la interfaz de prueba de",
        enterEP: "Introduce tu correo electrónico y contraseña",
        instTest: "Instrucciones",
        sendInt: "Interfaz de envío",
        unPlP: "Elija uno o más perfiles reales.",
        unNouv: "Nueva",
        unReç: "Recibidas",
        unNouv1: "Nuevo",
        payTot: "Pago total",
        allou: "Ya asignados",
        Nallou: "Aún no asignados",
        totSoum: "Mensaje total",
        Soum: "Enviado a QC",
        nonSoum: "No enviado a QC",
        boot: "Boot",
        rvr: "Citas/Reuniones/Vacaciones",
        banit: "Prohibido",
        suspt: "Suspendido",
        aucunt: "Ningún mensaje",
        msgstop: "Por favor redacte un mensaje de seguimiento",
        messrt: "Tasa de mensajes",
        pkmessrt: "Tasa de mensajes poke",
        tsrmessrt: "Tasa de mensajes teaser",
        stpmessrt: "Tasa de mensajes de stop",
        bnsrt: "Tasa de bonificación",
        mesdes: "Enviar mensajes en un sitio de citas es crucial para captar la atención y despertar el interés de la otra persona. Lo esencial es ser auténtico, mostrar un interés genuino y hacer preguntas abiertas que fomenten una respuesta.",
        mesdes1: "Entre los perfiles, elige uno real y uno falso para enviar un mensaje.",
        mesdes2: "Asegúrese de elegir el idioma de correspondencia adecuado.",
        mesdes3: "Redacta tu mensaje de prueba.",
        mesdes4: "Tienes 5 minutos para responder al mensaje en la plataforma de animación para operadores.",
        mesdes5: "Verifica tus estadísticas en tu panel de administración.",
        stopdes: "Los mensajes de seguimiento en un sitio de citas están destinados a reavivar el interés y reactivar la conversación después de un período de silencio.",
        stopdes1: "Elige un perfil real y uno falso",
        stopdes2: "Redacta un mensaje habitual",
        stopdes3: "Espera 24 horas después de que el operador de chat haya respondido al mensaje del perfil real",
        stopdes4: "Después de 24 horas, el mensaje no respondido por el perfil real se convierte en un Mensaje de seguimiento, que el operador se encargará de redactar y enviar.",
        stopdes5: "Verifica tus estadísticas en tu panel de administración",
        teaserdes: "Los mensajes de atracción en un sitio de citas son cruciales para captar la atención y despertar el interés de la otra persona. Lo esencial es ser auténtico, mostrar un interés genuino y hacer preguntas abiertas que fomenten una respuesta",
        teaserdes1: "Entre los perfiles, elige uno real y uno falso para enviar un mensaje de atracción",
        teaserdes2: "Asegúrate de elegir el idioma de correspondencia adecuado",
        teaserdes3: "En la plataforma de animación para operadores, redacta los mensajes de atracción",
        teaserdes5: "Verifica tus estadísticas en tu panel de administración",
        pokedes: "Los mensajes 'Poke' en un sitio de citas son mensajes cortos y ligeros, diseñados para captar la atención de manera sutil y atractiva.",
        pokedes1: "Elige el país y el idioma de los perfiles reales con los que deseas enviar mensajes Poke",
        pokedes2: "Selecciona uno o varios perfiles con los que deseas enviar mensajes Poke",
        pokedes3: "Verifica tus estadísticas en tu panel de administración",
        stopbla: "Lista de mensajes de seguimiento ya enviados",
        respVp: "Respuesta del perfil real",
        pasEn: "aún no.",
        notifAccr: "Mensaje de enganche de prueba: Selección de perfil realizada con éxito",
        notifPoke: "Mensaje Poke de prueba: Selección de perfil realizada con éxito",
        pokeErr: "Haz clic en uno o más perfiles reales",
        teaserErr: "haz clic en un perfil falso y uno real",
        reqGift : "Solicitar regalos",
        reqGift1 : "Tipo de regalo",
        reqGift2 : "Solicitud de regalo",
    },

    { // de

        usrsd : "Benutzerseite",
        btsd : "Botseite",
        nttkn : "Notizen gemacht",
        mssdnt : "Verpasste Notizen",
        gfts : "Geschenke",
        rqsts : "Anfragen",
        messonhldallctd : "Nachricht in Warteschleife bereits zugewiesen",
        messonhldntallctd : "Nachricht in Warteschleife nicht zugewiesen",
        totnummess : "Gesamtanzahl der Nachrichten",
        messpssdqc : "Nachrichten haben die Qualitätskontrolle bestanden",
        messntpssqc : "Nachrichten haben die Qualitätskontrolle nicht bestanden",
        paymnts : "Zahlungen",
        bnk : "Bank",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Empfangen",
        envoyees: "Gesendet",
        dmndees: "Angefordert",
        nvaux: "Neu",

        adddest: "E-Mail Adresse des Empfängers",

        cmpn: "Unternehmen",
        tmfld: "Textverarbeitungsordner",
        accar: "Zugangsbereich",
        assvldt: "Zugangsberechtigung",

        acc8: "Tarif und Bonus einrichten",

        mtng: "Sitzung",
        rp: "Echtes Profil",
        fp: "Gefälschtes Profil",
        infopernoper: "Permanente / nicht-permanente Information",
        dltnt: "Notiz löschen",
        dltmesspnc: "Wollen Sie diese Panikmeldung wirklich löschen?",
        convbnnd: "Konversation verboten",
        suspconv: "Unterbrechung der Konversation",
        upldd: "Hochgeladen",
        addpht: "Foto hinzufügen",
        phtsnttofp: "Liste der an gefälschte Profile gesendeten Fotos",
        pkmesscrtd: "Poke-Nachricht erfolgreich erstellt",
        updt: "Aktualisierung",
        dltpkmess: "Wollen Sie diese Poke-Nachricht wirklich löschen?",
        plt: "Plattform",
        dltdmd: "Möchten Sie diese Anfrage wirklich löschen? ",
        agntinfrmd: "Agent informiert",
        dlttsrmess: "Möchten Sie diese Teaser-Nachricht wirklich löschen?",
        updttsrmess: "Teaser-Nachricht aktualisieren",

        updtdsucc: "Erfolgreich aktualisiert",
        nonotif: "Keine Benachrichtigung",
        continfo: "Inhaltliche Informationen",
        exp: "Absender",
        recep: "Empfänger",
        mdpupdtd: "Passwort erfolgreich aktualisiert",
        opsusp: "Operator erfolgreich suspendiert",
        opdec: "Bediener erfolgreich abgemeldet",
        decop: "Möchten Sie die Verbindung zu diesem Betreiber wirklich trennen:  ",
        frop: "Möchten Sie diesen Operator wirklich feuern:  ",
        chngmdp: "Passwort ändern",
        suspop: "Möchten Sie diesen Operator wirklich suspendieren: ",
        onhld: "In der Warteschleife",
        supprmess: "Diese Nachricht löschen",
        messinfo: "Nachrichteninformationen",
        vld: "Bestätigen",
        ok: "Ok",
        enc: "In Arbeit ...",
        
        updtmdp: "Möchten Sie das Passwort ändern?",
        nwmdp: "Geben Sie ein neues Kennwort ein",
        dltsup: "Möchten Sie diesen Betreuer wirklich löschen?",
        ntsup: "Notiz an eine Aufsichtsperson",
        nthr: "Notiz hier...",
        dltimg: "Wollen Sie dieses Bild wirklich löschen?",
        phttsnd: "Foto zum Versenden",
        vd: "Leer (wählen Sie ein Foto aus)",
        addd: "Hinzugefügt",
        nwrdv: "Neue Informationen hinzufügen",
        cnfrm: "Bestätigen",
        dltrdv: "Möchten Sie dieses Rendez-vous/ Meetings/ Holidays wirklich löschen?",
        addnonperminfo: "Nicht dauerhafte Informationen hinzufügen",
        dltinfo: "Möchten Sie diese Information wirklich löschen?",
        idd: "ID",
        addperminfo: "Permanente Informationen hinzufügen",
        messreport: "Meldung an den Vorgesetzten",
        usrnm: "Benutzername",

        pp11 : "Creativ'Network, ein Unternehmen zur beruflichen Vermittlung für Webarbeiten, mit Sitz in 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, und mit einer Niederlassung in der Roodborstjesstraat 4, Wondelgem 9032, im Folgenden „die Gesellschaft“ genannt,",
        pp12 : "Und:",
        pp13 : "Selbständiger, der aufgrund des Selbständigenvertrags, der ihn mit „der Gesellschaft“ verbindet, die Materialien sowie die geistigen Eigentumsrechte dieser letzten nutzt, im Folgenden „der Selbständige“ genannt,",
        pp14 : "Im Folgenden einzeln als „die Partei“ und gemeinsam als „die Parteien“ bezeichnet,",
        pp21 : "Die Gesellschaft betreibt eine Plattform zur Verwaltung von Dating-Websites, deren ausschließliche Eigentümerin sie ist. Das operative Management dieser Plattform wird von Chat-Operatoren, Aufsichtspersonen, Teamleitern und Qualitätsprüfern übernommen, die von der Gesellschaft als Selbständige beschäftigt werden.",
        pp22 : "Im Rahmen dieser beruflichen Beziehung wird der Selbständige Zugang zu vertraulichen Informationen im Zusammenhang mit der Plattform, den Nutzern der Dating-Websites, den Betriebsprozessen sowie zu anderen geschützten Informationen der Gesellschaft haben.",
        pp23 : "Vorausgesetzt wird Folgendes vereinbart:",
        pp31 : "Diese Vertraulichkeitsvereinbarung dient dem Schutz der vertraulichen Informationen, die die Gesellschaft dem Selbständigen im Rahmen seiner Aufgaben im Zusammenhang mit der Verwaltung der Plattform für Dating-Websites mitteilen könnte.",
        pp41 : "Für die Zwecke dieser Vereinbarung bezeichnen die „Vertraulichen Informationen“ alle Informationen, in welcher Form auch immer (schriftlich, mündlich, elektronisch usw.), die die Plattform für Dating-Websites, die Nutzer, die Entwicklungsstrategien, die technischen und operativen Prozesse, die Finanzdaten sowie andere von der Gesellschaft als vertraulich bezeichnete Informationen betreffen, die der Selbständige im Rahmen seiner Arbeit erhalten oder auf die er zugreifen kann.",
        pp51 : "Der Selbständige verpflichtet sich:",
        pp52 : "- Vertrauliche Informationen, ganz oder teilweise, nicht ohne vorherige schriftliche Genehmigung der Gesellschaft an Dritte weiterzugeben, zu reproduzieren, zu übertragen oder zugänglich zu machen.",
        pp53 : "- Vertrauliche Informationen nur im Rahmen der Aktivitäten im Zusammenhang mit der Verwaltung der Plattform und ausschließlich im Interesse der Gesellschaft zu verwenden.",
        pp54 : "- Alle notwendigen Maßnahmen zu ergreifen, um die Vertraulichkeit der Vertraulichen Informationen zu schützen und eine unbefugte Nutzung zu verhindern.",
        pp55 : "- Auf Aufforderung der Gesellschaft alle Dokumente oder Materialien, die Vertrauliche Informationen enthalten, zurückzugeben, ohne Kopien in irgendeiner Form zu behalten.",
        pp61 : "Diese Vereinbarung tritt in Kraft, sobald der Mitarbeiter bei seiner Identifizierung für den Zugang zur Animationsplattform sein Einverständnis erklärt, und bleibt während der gesamten Dauer der beruflichen Beziehung zwischen der Gesellschaft und dem Selbständigen in Kraft. Die Vertraulichkeitsverpflichtungen bleiben für einen Zeitraum von 10 Jahren nach Beendigung dieser Beziehung bestehen, unabhängig vom Grund.",
        pp71 : "Nicht als Vertrauliche Informationen gelten:",
        pp72 : "- Informationen, die zum Zeitpunkt ihrer Mitteilung an den Selbständigen bereits öffentlich bekannt waren oder auf andere Weise öffentlich werden, es sei denn durch Verletzung dieser Vereinbarung.",
        pp73 : "- Informationen, die der Selbständige nachweisen kann, rechtmäßig von einem Dritten erhalten zu haben, der nicht durch eine Geheimhaltungspflicht gebunden ist.",
        pp74 : "- Informationen, die der Selbständige unabhängig entwickelt hat, ohne auf Vertrauliche Informationen zurückzugreifen.",
        pp81 : "Im Falle eines Verstoßes gegen die in dieser Vereinbarung festgelegten Verpflichtungen erkennt der Selbständige an, dass die Gesellschaft alle erforderlichen rechtlichen Schritte einleiten kann, um ihre Rechte zu schützen und Schadensersatz für erlittene Schäden zu erhalten.",
        pp91 : "Diese Vereinbarung unterliegt dem belgischen Recht. Im Falle eines Streits über die Auslegung oder Durchführung dieser Vereinbarung verpflichten sich die Parteien, eine einvernehmliche Lösung zu suchen. Bei fehlender einvernehmlicher Lösung sind ausschließlich die zuständigen Gerichte in Gent zuständig.",
        pp1 : "Zwischen den Parteien",
        pp2 : "Präambel",
        pp3 : "Artikel 1: Gegenstand",
        pp4 : "Artikel 2: Definition der Vertraulichen Informationen",
        pp5 : "Artikel 3: Verpflichtungen des Selbständigen",
        pp6 : "Artikel 4: Dauer",
        pp7 : "Artikel 5: Ausschlüsse",
        pp8 : "Artikel 6: Allgemeine Bestimmungen",
        pp9 : "Artikel 7: Anwendbares Recht und Zuständige Gerichtsbarkeit",

        paymail: 'Zahlungs-E-Mail-Adresse',
        scc: 'Erstellt',
        italian: 'Italienisch',
        dutch: 'Niederländisch',
        spanish: 'Spanisch',
        german: 'Deutsch',
        convonhold: "Gespräche in der Warteschleife",
        supdash: "Dashboard für Vorgesetzte",
        choverv: "Chat-Übersicht",
        login: 'Anmeldung',
        password: 'Passwort',

        qlogout: 'Möchten Sie sich abmelden?',
        logout: 'Abmelden',
        editNote:'Notiz Bearbeiten',
        yes: 'Ja',
        notesuppr:'Note gelöscht',
        addnote:'Anmerkung hinzugefügt',
        editer:'Bearbeiten',
        annuler:'Abbrechen',
        no: 'Nein',
        operatorpage: "Bedienerseite",
        supervisorpage: "Seite für Vorgesetzte",
        adminpage: "Administratoren-Seite",

        required: "Dieses Feld ist erforderlich",
        invalid: "Ungültige E-Mail",
        registered: "Diese E-Mail-Adresse st nicht registriert",
        incorrectpass: "Falsches Passwort",
        atleast: "Das Passwort muss mindestens enthalten",
        characters: "Zeichen",

        convman: "Gesprächsführung",
        agman: "Agenten-Verwaltung",
        notesman: "Verwaltung von Notizen",
        agrep: "Agentenberichte",
        gftgtfman: "Verwaltung von Geschenken und gifs",
        tmfldaa: "Zugriffserlaubnis für TextModing-Ordner",
        hlp: "Hilfe",
        pltflng: "Plattformsprache",
        pr: "Fotoanfragen",
        wlcm: "Willkommen",
        wlcmtxt: "TextModing ist eine Animationsplattform, die für Dating-Seiten entwickelt wurde und wesentliche Funktionen für die Verwaltung von Konversationen und Agenten bietet. Sie steigert die Effizienz und Rentabilität für Partner, indem sie Tools bereitstellt, die Interaktionen rationalisieren und das Engagement der Nutzer optimieren.",

        messageto: "Nachricht an",
        convatt: 'Gespräch in der Warteschleife',
        mincharlen: 'Mindestens 120 Zeichen',
        char: 'Zeichen',
        chars: 'Zeichen',
        pj: 'Fotoanhang',
        writemessage: 'Schreibe eine Nachricht ...',
        picalreadysent: 'Foto vom Benutzer gesehen',
        gifpj: 'GIFS',
        opengif: 'GIF öffnen',
        sendMessage: 'Senden Sie die Nachricht',
        reportSup: 'Notiz an den Supervisor senden',
        conv: 'Konversations-ID',
        say: 'sagt',
        timeIntro: 'bei',
        elements: 'elements',
        element: 'element',
        none: 'Keine',

        stat: 'Statistik',
        french: 'Französisch',
        english: 'Englisch',
        botprofil: 'Bot-Profil',
        userprofil: 'Benutzerprofil',
        otherInfo: 'Andere wichtige Informationen',
        codepost: 'Postleitzahl',
        ville: 'Stadt',
        region: 'Region',
        pays: 'Land',
        taille: 'Größe',
        Augen: 'Augenfarbe',
        hair: 'Haarfarbe',
        morph: 'Morphologie',
        situation: 'Familienstand',
        searchfor: 'Suche nach',
        job: 'Beruf',
        signe: 'Unterscheidungsmerkmale',
        descri: 'Beschreibung',
        infosupp: 'Zusätzliche Informationen',

        supervisiontchat: 'Chat-Überwachung',
        statconvop: 'Konversation zwischen Stat und Operator',
        convs: 'Unterhaltungen',
        report: 'Bericht',
        agentInform:'Informierter Agent',
        events: 'Ereignis',
        photosrequests: 'Fotos anfordern',
        warmup: 'Aufwärmen',
        gifattch: 'Gif-Anhang',
        fakeprofiles: 'Gefälschte Profile',
        pagenotfound: 'Seite nicht gefunden',

        suivvraiprof: 'Überwachung echter Profile',
        suiviop: 'Operatoren-Überwachung',
        admintion: 'Verwaltung',
        affiliation: 'Zugehörigkeit',

        operator: 'Operator',
        supervisor: 'Vorgesetzter',
        administrator: 'Administrator',

        week: 'Woche',
        weeks: 'Wochen',
        today: 'Heute',

        start: 'Start',
        end: 'Ende',
        platform: 'Plattformen',
        search: 'Suche',
        go: 'Los',
        allmasc: 'Alle',
        allfem: 'Alle',

        id: 'Bezeichner',
        opname: 'Name des Betreibers',
        sentmess: 'Gesendete Nachrichten',
        recmess: 'Empfangene Nachrichten',
        relmess: 'gemahnte Nachrichten',

        date: 'Datum',
        messid: 'Kennung der Nachricht',
        convid: 'Kennung des Gesprächs',
        exped: 'Absender',
        recpt: 'Empfänger',
        opsign: 'Zu meldender Bediener',
        opexped: 'Sendender Operatorr',
        action: 'Aktion',
        messs: 'Meldungen',
        filterdate: 'Suche zwischen zwei Daten filtern',
        filtermess: 'Konversation nach Nachricht filtern',
        from: 'Von',
        to: 'bis',
        eventList: 'Ereignisliste',
        num: 'Nummer',
        eventSearch: 'Suche nach einem Ereignis',
        actions: 'Aktionen',

        eventname: 'Name des Ereignisses',
        eventdate: 'Datum der Veranstaltung',
        uploadimage: "Bilder hochladen",
        role: "Rolle",
        logIn: "Anmelden",
        ppaccept1: "Mit der Anmeldung auf unserer Plattform bestätigen Sie, dass Sie unsere",
        ppaccept2: "gelesen haben und dass Sie die entsprechenden Schulungen absolviert haben, um Ihren Auftrag auszuführen",
        pp: "Vertraulichkeitsvereinbarung",
        copyright: "© 2023-2024 Copyright TextModing® - Alle Rechte vorbehalten.",
        accountant: "Buchhalter",
        qualitycontroller: "Qualitätskontrolleur",

        adminid: "Verwaltungs-ID",
        accountantid: "Buchhalter-ID",
        operatorid: "Bediener-ID",
        qcid: "Qualitätskontrolleur-ID",
        mail:'Email',
        supervisorid: "Vorgesetzten-ID",
        confirmpass:'Bestätigen Sie das Passwort',
        alreadyreg:'Bereits registriert? Einloggen',
        notifs: "Benachrichtigungen",
        limite:'Begrenzt',
        save:'Speichern',
        archives:'Archive',
        ttlmsg:'Gesamte Nachrichten',
        controlLog:'Controller-Login',
        nbrmess:'Anzahl der Nachrichten',
        appQC:'QC-Bewertungen',
        esqmq:'Fehlendes Ausweichen',
        dbspace:'Doppelter Abstand',
        notelu:'Notiz gelesen',
        actpris:'Maßnahme ergriffen',
        ferme:'Geschlossen',
        lorn:'Liste der erhaltenen Notizen',
        losn:'Liste der gesendeten Notizen',
        validity:'Geldigheid',
        editInfo:'Informationen bearbeiten',
        controlID:'ID-Controller',
        statcontrolct:'Kontrollstatistiken nach Controller',
        statcontrolag:'Kontrollstatistiken nach Agent',
        statcontrol:'Kontrollstatistiken',
        traitement:'Behandlung',
        agentid:'Agenten-ID',
        statconvatt:'Statistiken der Anrufe in der Warteschleife',
        propesqu:'Geben Sie eine Ausrede für eine Verabredungsanfrage ein',
        msgacc:'Bitte verfassen Sie eine ansprechende Nachricht',
        msgpoke:'Bitte schreiben Sie eine Poke-Nachricht',
        heure:'Stunde',
        esqv:'Ausweichen',
        esquive:'Ausweichmanöver Treffen und Termine',
        other:'Andere',
        sexe:'Geschlecht',
        bannir:'Verbannen',
        deconnecter:'Trennen',
        agntLgn:'Online-Agent',
        features:'Funktionen',
        valider:'Bestätigen',
        rdv:'Termin',
        holiday:'Urlaub',
        panicmsg:'diese Paniknachricht?',
        jour:'Tag',
        jours:'Tage',
        conversation:'Gespräch',
        profil:'Profil',
        suspendre:'Aussetzen',
        expulser:'Vertreiben',
        information:'Information',
        meeting:'Besprechung',
        online:'Online',
        onHold:'In Warteschleife',
        treated:'Behandelt',
        listPhotoSend:'Liste gesendeter Fotos',
        agentReport:'Gemeldeter Agent',
        suspendu:'Suspendiert',
        raison:'Grund',
        suspend:'Suspendieren',
        etat:'Status',
        actqc4:'Nachricht validiert',
        panicmess:'Paniknachricht',
        contenu:'Inhalt',
        language1:'Sprache',
        reportmess:'Nachricht melden',
        enreg:['Noch nicht registriert?','Registrieren'],
        passdiff:'Anderes Passwort',
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        year: 'Jahr',
        month: 'Monat',
        reg:'Registrieren',
        startingdate: 'Anfangsdatum',
        closingdate: 'Abschlussdatum',

        agents: "Agenten",
        out: "Ausgänge",
        in: "Eingänge",
        details: "Details",
        panicroom: "Panikraum",
        total: "Gesamt",
        notes: "Notizen",
        teasermessages: "Teaser-Nachrichten",
        pokemessages: "Poke-Nachrichten",
        attdisc:'Warten auf Diskussion',
        stopmessages: "Stopp-Nachrichten",
        gifajt:'Gif Hinzufügen',
        sent: "Gesendet",
        asnwered: "Beantwortet",
        requested: "Angefordert",
        logbook: "Logbuch",
        recorded: "Aufgezeichnet",
        notrecorded: "Nicht registriert",
        duplicatedmessages: "Doppelte Nachrichten",
        back: "Zurück",
        suty: "Abonnementtyp",
        ag: "Alter",
        gend: "Geschlecht",
        sexor: "Sexuelle Orientierung",
        tow: "Stadt",
        
        users: "Benutzer",
        sender: "Absender",
        receiver: "Empfänger",
        agent: "Agent",
        status: "Status",
        read: "Gelesen",
        active: "Aktiv",
        edit: "Bearbeiten",
        user: "Benutzer",
        sendnote: "Eine Notiz senden",
        oh: "In der Warteschleife",
        mess: "Nachricht",
        trconv: "Bearbeitete Konversationen",
        idconv: "ID der Konversation",
        idmess: "ID der Nachricht",
        otherimp: "Andere wichtige Informationen",
        otherimp1: "Treffen/ Begegnungen/ Ferien",
        otherimp2: "Ständige Informationen",
        otherimp3: "Nützliche Informationen, nicht dauerhaft",
        
        aglist: "Liste der Agenten",
        pfsperf: "Leistung von Plattformen",
        agperf: "Leistung der Agenten",
        nwag: "Neue Agenten hinzufügen",
        agname: "Name des Agenten",
        agcountry: "Land des Agenten",
        aglogin: "Login des Agenten",
        agloginpw: "Kennwort des Agenten",
        langpf: "Sprachplattform",
        onl: "Online",
        offl: "Offline",
        susp: "Suspendieren",
        koff: "Zurückschicken",
        grotot: "Bruttogesamt",
        agnumbewoon: "Anzahl der gefälschten Profile",
        totsenmess: "Summe der gesendeten Nachrichten",
        totrecmess: "Summe der empfangenen Nachrichten",
        totrep: "Gesamtanzahl der Berichte",
        real: "Echter Name",
        prov: "Bundesland",
        weight: "Gewicht",
        child: "Kinder",
        time: "Zeit",
        fstnm: "Vorname",
        lstnm: "Nachname",
        dtofbir: "Geburtsdatum",
        skypeid: "Skype-ID",
        crnwag: "Neuen Agenten erstellen",
        usrprfl: "Benutzerprofil",
        sndmess: "Nachricht senden",
        blckusr: "Benutzer sperren",
        lstnts: "Liste der Notizen",
        crtsndnts: "Notiz erstellen / senden",
        evnt: "Ereignis",
        ntid: "Notiz-ID",
        nt: "Notiz",
        dlt: "Löschen",
        cndid: "Kandidat ID",
        language: "Sprache",
        perc1: "Sie haben ",
        perc2: " der Nachricht des Benutzers verfasst",
        giftsatt: "Geschenke beigefügt",
        sndnt: "Senden Sie die Notiz",
        lsttsrmsg: "Liste der Teaser-Nachrichten",
        crttsrmsg: "Teaser-Nachrichten erstellen",
        idtsrmsg: "ID der Teaser-Nachricht",
        tsrmsg: "Teaser-Nachricht",
        dlvrdt: "Zustellung an",
        wiseg:"Kluge Gifs",         
        sexg:"Sexuelle Gifs",                        
        gayg:"Schwule Gifs",
        Lesbg: "Lesbische Gifs",
         transg:"Transgender Gifs",
        travg:"Travestie-Gifs",
                
        bdsm:"BDSM",
        dont: "Nicht lange fackeln",
        blj:"Blowjob",
        org:"Orgasmus",
        vagpen:"Vaginale Penetration",
        sod:"Sodomie",
        titss:"Titten",

        bdsmg: "BDSM schwul",
        wisegg: "Kluge Gay Gifs",
        sexgg:"Sex-Gay-Gifs",

        bdsml: "BDSM Lesbisch",
        wiselg:"Kluge Lesben Gifs",
        sexlg:"Sex Lesbian Gifs",

        maletrans:"Männlicher Transgender",
        femaletrans:"Weibliche Transgender",
        lstpkmsg: "Liste der Stopfmeldungen",
        crtpkmsg: "Erzeugen einer Poke-Nachricht",
        idpkmsg: "ID der Poke-Nachricht",
        pkmsg: "Poke-Nachricht",
        lststpmsg: "Liste der Stoppmeldungen",
        idstpmsg: "Stoppmeldungs-ID",
        stpmsg: "Stopp-Nachricht",
        sntto: "Gesendet an",
        agntwrtngstp: "Agent, der den Stopp schreibt",
        dtofdlvr: "Datum der Zustellung",
        dtofans: "Datum der Antwort",
        stpperfanal: "Leistungsanalyse stoppen",
        stpgrssttl: "Bruttosumme anhalten",
        rsptostp: "Antwort auf Stopp",
        rsptostp2: "Antwort auf den Hook",
        rsptostp3: "Antwort auf den Poke",
        stpprcntresp: "Stoppen Sie % Antwort",
        avrgrsptm : "Durchschnittliche Antwortzeit",

        lstphtrq: "Liste der angeforderten Fotos",
        phttp: "Foto-Typ",
        idrq: "Anfrage-ID",
        pht: "Foto",
        upld: "Hochladen",
        snd: "Senden",
        lstrprt: "Liste der Berichte",
        rpttp: "Berichtstyp",
        idrprt: "Berichts-ID",
        msgrprtd: "Gemeldete Nachricht",
        infrmagnt: "Bearbeiter informieren",
        lstlgbk: "Liste des Logbuchs",
        lstmssnglgbk: "Liste des fehlenden Logbuchs",
        lgbktp: "Logbuch-Typ",
        idlgbk: "Logbuch-ID",
        infs: "Informationen",
        lvlofimprt: "Grad der Wichtigkeit",
        prmnnt: "Dauerhaft",
        ntprmnnt: "Nicht dauerhaft",
        mssnglgbk: "Fehlendes Logbuch",
        mrklgbk: "Logbuch markieren",
     
        dh: "Datum und Uhrzeit",
        mid: "Nachrichten-ID",
        repmess: "Diese Nachricht melden",
        bts: "Bot sagt",
        obj: "Objekt",
        plc: "Ort",

        lstdplmss: "Liste der duplizierten Nachrichten",
        iddplmss: "ID der doppelten Nachrichten",
        lstgfs: "Liste der GIFs",
        ddgfs: "Neues GIF hinzufügen",
        gftp: "GIF-Typ",
        gfimg: "GIF-Bild",

        nat: "Natur",
        tmslt: "Zeitschlitz",
        gfsnt: "Gesendete GIFs",

        qcdash: "Dashboard für Qualitätskontrolle",
        qctrl: "Qualitätskontrolle",
        lstmess: "Liste der Meldungen",
        qcpnl: "Qualitätskontroll-Panel",
        ptreq: "Foto anfordern",
        sp: "Foto senden",
        sgft: "Geschenk senden",
        sgif: "Gif senden",
        eyes: "Farbe Augen",
        qccrit: "QC-Kriterien",
        qccrit1: "Gutes Gespräch",
        qccrit2: "Schlechter Chat",
        qccrit3: "Benutzerprofil betrachtet",
        qccrit4: "Bot-Profil betrachtet",
        qccrit5: "Vom Bot geführte Konversation",
        qccrit6: "CTA durch Agent",
        qccrit7: "Konversation sinnlos",
        qccrit8: "Benutzer unzufrieden mit dem Bot",
        qccrit9:'Gut zur Kenntnis genommen',
        actqc1: "Informiere den Agenten",
        actqc2: "Den Agenten aussetzen",
        actqc3: "Feuere den Agenten",
        adc1:'Ausgezeichneter Agent',
        adc2:'Durchschnittlicher Agent',
        adc3:'Schlechter Agent',
        sndmailtoadm: "Senden Sie eine E-Mail an den Administrator",
        yrnm: "Ihr Name",
        yrcmpnm: "Ihr Firmenname",
        yrmladdr: "Ihre E-Mail Adresse",
        yradmid: "Ihre Admin-ID",
        objctfyrrqst: "Objekt Ihrer Anfrage",
        yrmss: "Ihre Nachricht",
        yrattchmt: "Anlage",
        sndthml: "Senden Sie die E-Mail",
        ddnwadm: "Neuen Administrator hinzufügen",
        nm: "Name",
        mladd: "E-Mail Adresse",
        cmpnnm: "Firmenname",
        tmfldr: "TextModing-Ordner",
        psswrd: "Kennwort",
        accssar: "Zugangsbereiche",
        valdt: "Gültigkeitsdauer",
        rgstr: "Registrieren",

        accdash: "Dashboard für Buchhalter",
        acc: "Konten",
        acc1: "Bearbeiter-Statistik",
        acc2: "Zahlung per Banküberweisung",
        acc3: "Zahlung per Paypal",
        acc4: "Zahlung per Wise",
        acc5: "Zahlung per Yoursafe",
        acc6: "Einen Buchhalter hinzufügen",

        admdash: "Admin-Dashboard",
        chttlov: "Chattool-Übersicht",
        suptraffman: "Verkehrsüberwachung und -steuerung",
        vw: "Sehen",
        
        ratepermess: "Tarif pro Nachricht",
        bonus: "Bonus",
        totpay: "Gesamtzahlung",
        paymeth: "Zahlungsmethode",
        grosstot: "Bruttosumme zum Monat von",
        prstat: "Ausdruck des Kontoauszugs",
        agfn: "Vorname des Bearbeiters",
        agln: "Nachname des Bearbeiters",
        payadd: "Paypal-E-Mail-Adresse",
        nysent: "Noch nicht gesendet",
        wiseadd: "Wise-E-Mail-Adresse",
        yoursafeadd: "Yoursafe-E-Mail-Adresse",
        bankn: "Name der Bank",
        iban: "IBAN",
        bic: "BIC-Code",

        lstsup: "Liste der Supervisoren",
        ddnwsup: "Einen neuen Supervisor hinzufügen",
        trffcmng: "Verkehrsmanagement",
        supnm: "Name des Supervisors",
        suplgn: "Supervisor-Login",
        suplgnpsswrd: "Supervisor-Login-Passwort",
        idcnddt: "ID des Bewerbers",
        cntry: "Land",
        lgn: "Login",
        crtnwsup: "Einen neuen Supervisor erstellen",
        fragntonln: "Freier Agent online",
        msgalltag: "Dem Agenten zugewiesene Nachricht",
        allct: "Zuweisen",
        addqc: "Qualitätskontrolleur hinzufügen",
        crtqc: "Qualitätskontrolleur erstellen",
        crtnwacc: "Neuen Buchhalter anlegen",

        gifn: "GIFs zu senden",
        restfn: "Zurücksetzen",
        gifsel: "Leer (GIFs auswählen)",
        alerttxt: "GIFs erfolgreich gesendet.",
        option: "Optionen",

        phalert: "Anfrage für gesendetes Foto.",
        phtitre: "Fotoanfrage",
        phlabel: "Fototyp",
        phsend: "Senden",
        phload: "Laden...",

        delalert: "Gelöscht",
        deltitre1: "Möchten Sie diese Anfrage wirklich ",
        deltitre2: "löschen ?",
        delauteur: "Autor",
        deldesc: "Beschreibung",
        delaccept: "Ja",
        delrefuse: "Nein",
        delload: "Laden..",

        lstqc: "Liste der Qualitätskontrolleure",
        qcnm: "Name des Qualitätskontrolleurs",
        qclgn: "Anmeldung Qualitätslotsen",
        qclgnpsswrd: "Kennwort für den Qualitätskontrolleur",
        lstacc: "Liste der Buchhalter",
        accnm: "Name des Buchhalters",
        acclgn: "Buchhalter-Login",
        acclgnpsswrd: "Buchhalter-Login-Passwort",

        biev: "Willkommen",
        selecrt: "Nachrichtensendungsinterface",
        chxha: "Wählen Sie ein echtes und ein falsches Profil",
        chxF: "Liste der echten Profile",
        chxV: "Liste der falschen Profile",
        nomd: "Name",
        langs: "Sprache",
        errdj: "Wählen Sie ein echtes und ein falsches Profil aus",
        plch: "Geben Sie Ihre Nachricht ein",
        pokePh: "Poke-Nachrichten (noch nicht gesendet)",
        teaserPh: "Liste der Ankernachrichten (noch nicht gesendet)",
        inter: "Testoberfläche für TextModing",
        interSel: "Bitte wählen Sie die Aufgaben aus, die Sie ausführen möchten",
        des1: "Versand einer personalisierten Nachricht an ein Profil.",
        des2: "Versand einer Nachricht, um die Aufmerksamkeit des Profils zu erregen.",
        des3: "Personalisierter Versand von fesselnden Nachrichten an Neuanmeldungen, um Gespräche zu beginnen.",
        des4: "Versand einer Nachricht, um das Gespräch neu zu starten.",
        tit1: "Nachricht senden",
        tit2: "Teaser-Nachricht",
        tit3: "Poke-Nachricht",
        tit4: "Stopp-Nachricht",
        tache: "Aufgabe",
        bienvTest: "Willkommen in der Testoberfläche von",
        enterEP: "Geben Sie Ihre E-Mail und Ihr Passwort ein",
        instTest: "Anweisungen",
        sendInt: "Sendeinterface",
        unPlP: "Wählen Sie ein oder mehrere echte Profile aus.",
        unNouv: "Neue",
        unReç: "Erhaltene",
        unNouv1: "Neu",
        payTot: "Gesamtzahlung",
        allou: "Bereits zugewiesen",
        Nallou: "Noch nicht zugewiesen",
        totSoum: "Gesamtnachricht",
        Soum: "Eingereicht bei QC",
        nonSoum: "Nicht eingereicht bei QC",
        boot: "Bot",
        rvr: "Termine/Meetings/Urlaub",
        banit: "Gesperrt",
        suspt: "Suspendiert",
        aucunt: "Keine Nachricht",
        msgstop: "Bitte verfassen Sie eine Nachfassnachricht",
        messrt: "Nachrichtenrate",
        pkmessrt: "Poke-Nachrichtenrate",
        tsrmessrt: "Teaser-Nachrichtenrate",
        stpmessrt: "Stopp-Nachrichtenrate",
        bnsrt: "Bonusrate",
        mesdes: "Das Versenden von Nachrichten auf einer Dating-Website ist entscheidend, um die Aufmerksamkeit der anderen Person zu erregen und ihr Interesse zu wecken. Das Wichtigste ist, authentisch zu bleiben, echtes Interesse zu zeigen und offene Fragen zu stellen, die eine Antwort fördern.",
        mesdes1: "Wählen Sie unter den Profilen ein echtes und ein gefälschtes aus, um eine Nachricht zu senden.",
        mesdes2: "Achten Sie darauf, die richtige Korrespondenzsprache zu wählen.",
        mesdes3: "Verfassen Sie Ihre Testnachricht.",
        mesdes4: "Sie haben 5 Minuten Zeit, um auf die Nachricht auf der Animationsplattform für Operatoren zu antworten.",
        mesdes5: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard.",
        stopdes: "Follow-up-Nachrichten auf einer Dating-Website sollen das Interesse neu wecken und das Gespräch nach einer Phase der Stille wiederbeleben.",
        stopdes1: "Wählen Sie ein echtes und ein falsches Profil",
        stopdes2: "Verfassen Sie eine übliche Nachricht",
        stopdes3: "Warten Sie 24 Stunden, nachdem der Chat-Operator auf die Nachricht des echten Profils geantwortet hat",
        stopdes4: "Nach 24 Stunden wird die vom echten Profil nicht beantwortete Nachricht zu einer Follow-up-Nachricht, die der Operator verfassen und senden wird.",
        stopdes5: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard",
        teaserdes: "Teaser-Nachrichten auf einer Dating-Website sind entscheidend, um die Aufmerksamkeit zu erregen und das Interesse der anderen Person zu wecken. Es ist wichtig, authentisch zu bleiben, echtes Interesse zu zeigen und offene Fragen zu stellen, die eine Antwort fördern",
        teaserdes1: "Wählen Sie aus den Profilen ein echtes und ein gefälschtes aus, um eine Teaser-Nachricht zu senden",
        teaserdes2: "Achten Sie darauf, die richtige Korrespondenzsprache zu wählen",
        teaserdes3: "Verfassen Sie die Teaser-Nachrichten auf der Animationsplattform für Operatoren",
        teaserdes5: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard",
        pokedes: "Poke-Nachrichten für eine Dating-Website sind kurze und leichte Nachrichten, die dazu gedacht sind, subtil und ansprechend Aufmerksamkeit zu erregen.",
        pokedes1: "Wählen Sie das Land und die Sprache der echten Profile, an die Sie Poke-Nachrichten senden möchten",
        pokedes2: "Wählen Sie ein oder mehrere Profile aus, an die Sie Poke-Nachrichten senden möchten",
        pokedes3: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard",
        stopbla: "Liste der bereits gesendeten Folge-Nachrichten",
        respVp: "Antwort vom echten Profil",
        pasEn: "noch nicht.",
        notifAccr: "Testansprache Nachricht: Profilauswahl erfolgreich getroffen",
        notifPoke: "Test Poke Nachricht: Profilauswahl erfolgreich getroffen",
        pokeErr: "Klicken Sie auf ein oder mehrere echte Profile",
        teaserErr: "klicken Sie auf ein falsches und ein echtes Profil",
        reqGift : "Geschenke anfordern",
        reqGift1 : "Art des Geschenks",
        reqGift2 : "Geschenkanfrage",
    },
]
const getKey = lang =>{
    switch (lang){
        case 'fr': return 0
        case 'it': return 2
        case 'nl': return 3
        case 'es': return 4
        case 'de': return 5
        default: return 1 
    }
}
const getText = lang => { return text[getKey(lang)] }

export default getText
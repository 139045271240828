// src/components/state/StatContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';

const StatContext = createContext();

const StatProvider = ({ children }) => {
    const [stat, setStat] = useState(null);

    return (
        <StatContext.Provider value={{ stat, setStat }}>
            {children}
        </StatContext.Provider>
    );
};

export { StatProvider, StatContext };
